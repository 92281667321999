import { SignInSchema } from "@bussola-viagens/validations"
import { useTheme } from "@mui/material"
import { useFormik } from "formik"
import { useContext } from "react"
import { useNavigate } from "react-router-dom"
import { Button, Grid, Input, Paper } from "../../components"
import { AuthContext } from "../../contexts/AuthContext"

export const LoginPage = () => {
    const theme = useTheme()
    const {signIn} = useContext(AuthContext)
    const navigate = useNavigate()

    const formik = useFormik({
        initialValues: {
            password: '',
            email: ''
        },
        validationSchema: SignInSchema,
        onSubmit: async (values) => {
            signIn(values)
            // navigate('/home')
        }
    })

    return (
        <Grid container justifyContent={"center"} height={"100vh"} alignItems="center" style={{
            backgroundColor: theme.palette.primary.main
        }}>
            <Grid item xs={4}>
                <Paper>
                    <form onSubmit={formik.handleSubmit}>
                    <Grid container p={2} gap={2}>
                        <Grid item xs={12} textAlign="center">
                            <img src="logo.png" height="100px" alt="Bússola Logo"/>
                        </Grid>
                        <Grid item xs={12}>
                            <Input
                                label="Email"
                                name="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={!!formik.touched.email && !!formik.errors.email}
                                helperText={formik.touched.email && formik.errors.email}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Input 
                                label="Senha"
                                type="password"
                                name="password"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                error={!!formik.touched.password && !!formik.errors.password}
                                helperText={formik.touched.password && formik.errors.password}
                            />
                        </Grid>
                        <Grid item xs={12} textAlign="center">
                            <Button type="submit">
                                Entrar
                            </Button>
                        </Grid>
                    </Grid>
                    </form>
                </Paper>
            </Grid>
        </Grid>
    )
}