import { GridActionsCellItem, GridDeleteIcon, GridRowParams, GridViewColumnIcon } from "@mui/x-data-grid"
import { useContext } from "react"
import { Button, Grid, Input, Layout, Loader, LoaderContainer, Modal, Paper, Table } from "../../components"
import { AuthContext } from "../../contexts"
import DownloadIcon from '@mui/icons-material/Download';
import { useAPI, useToggle } from "../../hooks";
import { enqueueSnackbar } from "notistack";
import { formatDateTime, formatMoney } from "@bussola-viagens/utils";
import { Navigate, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { CreateTrainingSchema } from "@bussola-viagens/validations";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export const MyOffersPage = () => {
    const navigate = useNavigate()
    const [offers] = useAPI(`/offer`)
    const { me } = useContext(AuthContext)
    const [dowloadedFile, downloadFile] = useAPI({
        method: 'GET',
    }, {
        manual: true
    })

    if (!me?.group.group_module?.is_offer_enabled) {
        return (
            <Navigate to="/home" />
        )
    }

    if (offers.loading) {
        return (
            <LoaderContainer>
                <Loader />
            </LoaderContainer>
        )
    }

    return (
        <Layout>
            <Grid container p={1} justifyContent="center" spacing={1}>
                <Grid item xs={10} textAlign="right">
                    <Button href="/meus-orcamentos/novo">
                        Adicionar
                    </Button>
                </Grid>
                <Grid item xs={10}>
                    <Paper>
                        <Table 
                            columns={[
                                { field: 'created_at', flex: 1, type: 'dateTime', headerName: 'Data de Criação', valueGetter: (value) => new Date(value), valueFormatter: formatDateTime },
                                { field: 'buyer_name', flex: 1, type: 'string', headerName: 'Cliente' },
                                { field: 'has_flight', flex: 1, type: 'boolean', headerName: 'Voo' },
                                { field: 'has_hotel', flex: 1, type: 'boolean', headerName: 'Hotel' },
                                { field: 'payment_method', flex: 1, type: 'string', headerName: 'Forma de Pagamento' },
                                { field: 'final_price', flex: 1, type: 'number', align: 'left', headerAlign: 'left', headerName: 'Valor Final', valueFormatter: (value) => formatMoney(value) },
                                { field: 'actions', type: 'actions', getActions: (params: GridRowParams) => [
                                    <GridActionsCellItem 
                                        icon={<DownloadIcon />} 
                                        onClick={() => {
                                            downloadFile({
                                                url: `/file/${params.row.file_id}`,
                                                responseType: 'blob'
                                            }).then((res) => {
                                                if (res.status === 200) {
                                                    const blobUrl = URL.createObjectURL(res.data);

                                                    window.open(blobUrl, '_blank')
                                                }
                                            })
                                        }} 
                                        label="Fazer Download" 
                                    />,
                                    <GridActionsCellItem 
                                        icon={<ContentCopyIcon />} 
                                        onClick={() => {
                                            const getParams = new URLSearchParams({
                                                buyer_name: params.row.buyer_name,
                                                flight_cabin: params.row.flight_cabin,
                                                checked_luggages: params.row.flight_checked_luggages,
                                                flight_extra_service: params.row.flight_description,
                                                payment_method: params.row.payment_method,
                                                hotel_name: params.row.hotel_name,
                                                hotel_address: params.row.hotel_address,
                                                hotel_description: params.row.hotel_description,
                                                flight_price: params.row.flight_price,
                                                hotel_price: params.row.hotel_price,
                                                has_flight: params.row.has_flight,
                                                has_hotel: params.row.has_hotel,
                                                hotel_rules: params.row.hotel_rules
                                            })
                                            
                                            navigate(`/meus-orcamentos/novo?${getParams.toString()}`,)
                                        }} 
                                        label="Duplicar" 
                                    />,
                                ] }
                            ]}
                            rows={offers.data || []}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </Layout>
    )
}