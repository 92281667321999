import { formatDateTime } from "@bussola-viagens/utils"
import { GridActionsCellItem, GridDeleteIcon, GridRowParams } from "@mui/x-data-grid"
import { useFormik } from "formik"
import { enqueueSnackbar } from "notistack"
import { Fragment, useCallback, useEffect } from "react"
import { useParams } from "react-router-dom"
import { Button, FileUploader, Grid, Input, Layout, Loader, LoaderContainer, Modal, Paper, Table, Typography } from "../../../components"
import { useAPI, useToggle } from "../../../hooks"
import DownloadIcon from '@mui/icons-material/Download';
import { CreateLessonSchema, CreateSolicitationReplySchema, UpdateTrainingSchema } from "@bussola-viagens/validations"
import { Chip, ChipOwnProps, Divider } from "@mui/material"
import { nl2br } from "@bussola-viagens/utils"
import { SOLICITATION_STATUS } from "@bussola-viagens/constants"

export const SolicitationPage = () => {
    const { id } = useParams()
    const [replyModal, replyModalControls] = useToggle(false)
    const [solicitation, refetchSolicitation] = useAPI(`/solicitation/${id}`)
    const [created, create] = useAPI({}, {manual: true})

    const form = useFormik({
        initialValues: {
            description: '',
        },
        validationSchema: CreateSolicitationReplySchema,
        validateOnMount: true,
        onSubmit: replyModalControls.setTrue
    })

    const handleSubmitReply = useCallback(async () => {
        const { status } = await create({
            url: `/solicitation/${id}/reply`,
            data: form.values,
            method: 'POST'
        })

        if (status <= 299) {
            enqueueSnackbar('Pedido atualizado com sucesso!', { variant: 'success' })
            replyModalControls.setFalse()
            form.resetForm()
            refetchSolicitation()
        }
    }, [create, form, id, refetchSolicitation, replyModalControls])

    if (solicitation.loading || created.loading) {
        return (
            <LoaderContainer>
                <Loader />
            </LoaderContainer>
        )
    }
    
    return (
        <Layout>
            <Grid container p={1} spacing={1} justifyContent="center">
                <Grid item xs={6}>
                    <Paper sx={{
                        p: 2
                    }}>
                        <Typography variant="h6" textAlign="center">
                            ID: {solicitation.data.id}
                        </Typography>
                        <Divider sx={{
                            mt: 1,
                            mb: 1
                        }}/>
                        <Grid container spacing={2}>
                            <Grid item xs={12} justifyContent="space-between" flexDirection="row" display="flex">
                                <Typography fontWeight="bold">
                                    Status:
                                </Typography>
                                <Chip label={SOLICITATION_STATUS.find(s => s.value === solicitation.data.status)?.label} color={SOLICITATION_STATUS.find(s => s.value === solicitation.data.status)?.chipColor as ChipOwnProps['color']} />
                            </Grid>
                            <Grid item xs={12} justifyContent="space-between" flexDirection="row" display="flex">
                                <Typography fontWeight="bold">
                                    Data:
                                </Typography>
                                <Typography>
                                    {formatDateTime(solicitation.data.created_at)}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} justifyContent="space-between" flexDirection="row" display="flex">
                                <Typography fontWeight="bold">
                                    Autor:
                                </Typography>
                                <Typography>
                                    {solicitation.data.creator.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} justifyContent="space-between" flexDirection="row" display="flex">
                                <Typography fontWeight="bold">
                                    Grupo:
                                </Typography>
                                <Typography>
                                    {solicitation.data.group.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} justifyContent="space-between" flexDirection="row" display="flex">
                                <Typography fontWeight="bold">
                                    Título:
                                </Typography>
                                <Typography>
                                    {solicitation.data.title}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} justifyContent="space-between" flexDirection="row" display="flex">
                                <Typography fontWeight="bold">
                                    Descrição:
                                </Typography>
                                <Typography dangerouslySetInnerHTML={{
                                    __html: nl2br(solicitation.data.description)
                                }} />
                            </Grid>
                            <Grid item xs={12}>
                                <Divider sx={{
                                    mt: 1,
                                    mb: 1
                                }}/>
                            </Grid>
                            {solicitation.data.solicitation_reply ? (
                                <Fragment>
                                    <Grid item xs={12} justifyContent="space-between" flexDirection="row" display="flex">
                                        <Typography fontWeight="bold">
                                            Respondido por:
                                        </Typography>
                                        <Typography>
                                            {solicitation.data.solicitation_reply.creator.name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} justifyContent="space-between" flexDirection="row" display="flex">
                                        <Typography fontWeight="bold">
                                            Data:
                                        </Typography>
                                        <Typography>
                                            {formatDateTime(solicitation.data.solicitation_reply.created_at)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} justifyContent="space-between" flexDirection="row" display="flex">
                                        <Typography fontWeight="bold">
                                            Descrição:
                                        </Typography>
                                        <Typography dangerouslySetInnerHTML={{
                                            __html: nl2br(solicitation.data.solicitation_reply.description)
                                        }} />
                                    </Grid>
                                </Fragment>
                            ) : (
                            <Grid item xs={12} justifyContent="space-between" flexDirection="row" display="flex">
                                <Typography fontWeight="bold">
                                    Resposta:
                                </Typography>
                                <form onSubmit={form.handleSubmit}  style={{
                                    width: '50%',
                                    display: 'flex',
                                    flexDirection: 'row'
                                }}>
                                    <Input 
                                        name="description"
                                        label="Descreva uma resposta ao pedido..."
                                        multiline
                                        maxRows={4}
                                        rows={4}
                                        value={form.values.description}
                                        onBlur={form.handleBlur}
                                        onChange={form.handleChange}
                                        error={!!form.touched.description && !!form.errors.description}
                                        helperText={form.touched.description && form.errors.description}
                                    />
                                    <Button type="submit" style={{
                                        marginLeft: 5
                                    }}>
                                        Enviar
                                    </Button>
                                </form>
                            </Grid>)}
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
            {
                replyModal && (
                    <Modal
                        open={replyModal}
                        onClose={replyModalControls.setFalse}
                        confirmLabel="Confirmar"
                        onConfirm={handleSubmitReply}
                    >
                        <Fragment>
                            <Typography>Você tem certeza que deseja responder este pedido?</Typography>
                            <Typography>Esta ação é <strong>irreversível</strong> e não poderá ser desfeita.</Typography>
                        </Fragment>
                    </Modal>
                )
            }
        </Layout>
    )
}