import { UpdateGroupSchema } from "@bussola-viagens/validations"
import { Divider } from "@mui/material"
import { styled } from "@mui/system"
import { useFormik } from "formik"
import { enqueueSnackbar } from "notistack"
import { useEffect } from "react"
import { Button, Grid, Input, Loader, LoaderContainer, Paper, Switch, Table, Typography } from "../../../../components"
import { useAPI } from "../../../../hooks"

interface SettingsProps {
    groupId: string
}

export const Settings = ({groupId}: SettingsProps) => {
    const [group, refetchGroup] = useAPI(`/group/${groupId}`)
    const [updatedGroup, updateGroup] = useAPI({
        url: `/group/${groupId}`,
        method: 'PUT'
    }, {
        manual: true
    })

    const [users, refetchUsers] = useAPI(`/user`)

    const formik = useFormik({
        initialValues: {
            name: '',
            users: []
        },
        validationSchema: UpdateGroupSchema,
        onSubmit: async (values, helper) => {
            const { status } = await updateGroup({
                url: `/group/${groupId}`,
                data: values
            })

            if (status <= 299) {
                enqueueSnackbar('Grupo atualizado com sucesso!', { variant: 'success' })
                helper.setStatus('draft')
                refetchGroup()
                refetchUsers()
            }
        },
        initialStatus: 'draft'
    })

    useEffect(() => {
        if (group.data && formik.status === 'draft') {
            formik.resetForm({
                values: {
                    name: group.data.name,
                    users: group.data.users.map((user: { id: string }) => user.id)
                },
                status: 'initiated'
            })
        }
    }, [group.data, formik])

    if (group.loading || users.loading || formik.status === 'draft' || updatedGroup.loading) {
        return (
            <LoaderContainer>
                <Loader />
            </LoaderContainer>
        )
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={8}>
                <Paper>
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container gap={1} p={2}>
                            <Grid item xs={12}>
                                <Input 
                                    name="name"
                                    label="Nome"
                                    value={formik.values.name}
                                    onChange={formik.handleChange}
                                    error={!!formik.touched.name && !!formik.errors.name}
                                    helperText={formik.touched.name && formik.errors.name}
                                />
                            </Grid>
                            <Grid item xs={12} pt={2}>
                                <Table
                                    rowSelection={true}
                                    checkboxSelection
                                    onRowSelectionModelChange={(value) => {
                                        formik.setFieldValue('users', value)
                                    }}               
                                    rowSelectionModel={formik.values.users}   
                                    columns={[
                                        {
                                            field: 'name',
                                            type: 'string',
                                            headerName: 'Nome',
                                            flex: 1
                                        },
                                        {
                                            field: 'email',
                                            type: 'string',
                                            headerName: 'Email',
                                            flex: 1
                                        },
                                        {
                                            field: 'group',
                                            type: 'string',
                                            headerName: 'Grupo',
                                            flex: 1,
                                            valueGetter: (value: { name: string }) => {
                                                return value?.name ? value.name : ''
                                            }
                                        }
                                    ]}
                                    rows={users.data || []}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography fontStyle="italic" fontSize={14}>
                                    Se você selecionar um usuário que já está em um grupo, ele será removido e adicionado neste novo grupo.
                                </Typography>
                            </Grid>
                            <Grid item xs={12} textAlign="center" pt={2}>
                                <Button type='submit'>
                                    Salvar
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Paper>
            </Grid>
            <Grid item xs={4}>
                <Paper sx={{
                    p: 2
                }}>
                    <Typography variant="h6" textAlign="center">
                        Módulos
                    </Typography>
                    <Divider sx={{
                        mt: 1,
                        mb: 1
                    }} />
                    <Switch 
                        label="Orçamentos"
                        checked={group.data?.group_module?.is_offer_enabled}
                        onChange={async () => {
                            const { status } = await updateGroup({
                                url: `/group/${groupId}/module/OFFER/${group.data?.group_module?.is_offer_enabled ? 'disable' : 'enable'}`,
                            })

                            if (status <= 299) {
                                enqueueSnackbar('Grupo atualizado com sucesso!', {variant: 'success'})
                                refetchGroup()
                            }
                        }}
                    />
                    <Switch 
                        label="Documentos"
                        checked={group.data?.group_module?.is_documents_enabled}
                        onChange={async () => {
                            const { status } = await updateGroup({
                                url: `/group/${groupId}/module/DOCUMENTS/${group.data?.group_module?.is_documents_enabled ? 'disable' : 'enable'}`,
                            })

                            if (status <= 299) {
                                enqueueSnackbar('Grupo atualizado com sucesso!', {variant: 'success'})
                                refetchGroup()
                            }
                        }}
                    />
                    <Switch 
                        label="Treinamentos"
                        checked={group.data?.group_module?.is_training_enabled}
                        onChange={async () => {
                            const { status } = await updateGroup({
                                url: `/group/${groupId}/module/TRAINING/${group.data?.group_module?.is_training_enabled ? 'disable' : 'enable'}`,
                            })

                            if (status <= 299) {
                                enqueueSnackbar('Grupo atualizado com sucesso!', {variant: 'success'})
                                refetchGroup()
                            }
                        }}
                    />
                    <Switch 
                        label="Banco de Mídias"
                        checked={group.data?.group_module?.is_media_library_enabled}
                        onChange={async () => {
                            const { status } = await updateGroup({
                                url: `/group/${groupId}/module/MEDIA_LIBRARY/${group.data?.group_module?.is_media_library_enabled ? 'disable' : 'enable'}`,
                            })

                            if (status <= 299) {
                                enqueueSnackbar('Grupo atualizado com sucesso!', {variant: 'success'})
                                refetchGroup()
                            }
                        }}
                    />
                    <Switch 
                        label="Promoções"
                        checked={group.data?.group_module?.is_promotion_enabled}
                        onChange={async () => {
                            const { status } = await updateGroup({
                                url: `/group/${groupId}/module/PROMOTION/${group.data?.group_module?.is_promotion_enabled ? 'disable' : 'enable'}`,
                            })

                            if (status <= 299) {
                                enqueueSnackbar('Grupo atualizado com sucesso!', {variant: 'success'})
                                refetchGroup()
                            }
                        }}
                    />
                    <Switch 
                        label="Pedidos"
                        checked={group.data?.group_module?.is_solicitation_enabled}
                        onChange={async () => {
                            const { status } = await updateGroup({
                                url: `/group/${groupId}/module/SOLICITATION/${group.data?.group_module?.is_solicitation_enabled ? 'disable' : 'enable'}`,
                            })

                            if (status <= 299) {
                                enqueueSnackbar('Grupo atualizado com sucesso!', {variant: 'success'})
                                refetchGroup()
                            }
                        }}
                    />
                </Paper>
            </Grid>
        </Grid>
    )
}