import { Paper } from '../Paper'
import { useAPI } from '../../hooks'
import { Grid } from '../Grid'
import { Input } from '../Input'
import { Switch } from '../Switch'
import { Button } from '../Button'
import { useFormik } from 'formik'
import { useContext, useEffect } from 'react'
import { Loader, LoaderContainer } from '../Loader'
import { ROLES } from '@bussola-viagens/constants'
import { UpdateProfileSchema } from '@bussola-viagens/validations'
import { enqueueSnackbar } from 'notistack'
import { AuthContext } from '../../contexts'
import { USER_ROLE } from '@bussola-viagens/database'

interface ProfileProps {
    userId: string
}

export const Profile = ({userId}: ProfileProps) => {
    const {me} = useContext(AuthContext)
    const [profile] = useAPI(`/user/${userId}`)
    const [updatedProfile, updateProfile] = useAPI({
        url: `/user/${userId}`,
        method: 'PUT'
    }, {manual: true})

    const formik = useFormik({
        initialValues: {
            role: '',
            password: '',
            password_confirmation: '',
            active: true
        },
        validationSchema: UpdateProfileSchema,
        onSubmit: async (values) => {
            const { status } = await updateProfile({
                data: values
            })

            if (status <= 299) {
                enqueueSnackbar('Perfil atualizado com sucesso!', {variant: 'success'})
            }
        },
        initialStatus: 'draft'
    })

    useEffect(() => {
        if (profile.data && formik.status === 'draft') {
            formik.resetForm({
                values: {
                    role: profile.data.role,
                    active: profile.data.active,
                    password: '',
                    password_confirmation: ''
                },
                status: 'initiated'
            })
        }
    }, [profile.data, formik.status])

    if (!profile.data || updatedProfile.loading || formik.status === 'draft') {
        return (
            <LoaderContainer>
                <Loader />
            </LoaderContainer>
        )
    }

    return (
        <Paper>
            <form onSubmit={formik.handleSubmit}>
                <Grid container gap={1} p={2}>
                    <Grid item xs={12}>
                        <Input 
                            label="E-mail"
                            disabled
                            value={profile.data.email}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Input 
                            label="Nome"
                            disabled
                            value={profile.data.name}
                        />
                    </Grid>
                    {me?.role === USER_ROLE.ADMIN ? (<Grid item xs={12}>
                        <Input 
                            name="role"
                            label="Cargo"
                            select
                            options={ROLES}
                            value={formik.values.role}
                            onChange={formik.handleChange}
                            error={!!formik.touched.role && !!formik.errors.role}
                            helperText={formik.touched.role && formik.errors.role}
                        />
                    </Grid>) : null}
                    <Grid item xs={12}>
                        <Input 
                            type="password"
                            name='password'
                            label="Senha"
                            value={formik.values.password}
                            onChange={formik.handleChange}
                            error={!!formik.touched.password && !!formik.errors.password}
                            helperText={formik.touched.password && formik.errors.password}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Input 
                            type="password"
                            name="password_confirmation"
                            label="Confirmação da Senha"
                            value={formik.values.password_confirmation}
                            onChange={formik.handleChange}
                            error={!!formik.touched.password_confirmation && !!formik.errors.password_confirmation}
                            helperText={formik.touched.password_confirmation && formik.errors.password_confirmation}
                        />
                    </Grid>
                    {me?.role === USER_ROLE.ADMIN ? (<Grid item xs={12}>
                        <Switch 
                            label='Ativo'
                            checked={formik.values.active}
                            onChange={() => {
                                formik.setFieldValue('active', !formik.values.active)
                            }}
                        />
                    </Grid>) : null}
                    <Grid item xs={12} textAlign="center" pt={2}>
                        <Button type='submit'>
                            Salvar
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Paper>
    )
}