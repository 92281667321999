import { GridActionsCellItem, GridRowParams } from "@mui/x-data-grid"
import { useContext, useState } from "react"
import { Navigate, useParams } from "react-router-dom"
import { Grid, Layout, LinearProgressWithLabel, Loader, LoaderContainer, Paper, Table, Typography } from "../../../components"
import { useAPI } from "../../../hooks"
import { AuthContext } from "../../../contexts"
import { CheckOutlined, PlayCircleOutline } from "@mui/icons-material"
import currency from "currency.js"
import { debounce } from "lodash"
import styled from "@emotion/styled"

const StyledTable = styled(Table)`
    .selected {
        background-color: rgb(182 182 182 / 30%);

        &:hover {
            background-color: rgb(182 182 182 / 25%);
        }
    }
`

export const MyTrainingPage = () => {
    const { id } = useParams()
    const [training] = useAPI(`/training/${id}`)
    const [downloadedVideos, setDownloadedVideos] = useState<{[key: string]: string}>({})
    const [focusedLesson, setFocusedLesson] = useState<string>('')

    const [_, updateLessonWatch] = useAPI(`/training/${id}/lesson`, {
        manual: true
    })

    const [dowloadedFile, downloadFile] = useAPI({
        method: 'GET',
    }, {
        manual: true
    })

    const { me } = useContext(AuthContext)

    if (!me?.group.group_module?.is_training_enabled) {
        return (
            <Navigate to="/home" />
        )
    }

    if (training.loading || dowloadedFile.loading) {
        return (
            <LoaderContainer>
                <Loader />
            </LoaderContainer>
        )
    }

    const lesson = training?.data?.lessons?.find(({id}: {id: string}) => id === focusedLesson)
    
    if (focusedLesson && !downloadedVideos[focusedLesson] && !dowloadedFile.error && training?.data?.lessons) {
        downloadFile({
            url: `/file/${lesson.file_id}`,
            responseType: 'blob'
        }).then((res) => {
            if (res.status === 200) {
                const blobUrl = URL.createObjectURL(res.data);

                setDownloadedVideos(state => ({
                    ...state,
                    [focusedLesson]: blobUrl
                }))
            }
        })
    }

    if (!focusedLesson && training.data.lessons.length) {
        setFocusedLesson(training.data.lessons[0].id)
    }

    const handleSaveProgress = debounce(function (lessonId: string, progress: number) {
        updateLessonWatch({
            url: `/training/${id}/lesson/${lessonId}/watch`,
            data: {
                progress
            },
            method: 'PUT'
        })
    }, 1500);
    
    return (
        <Layout>
            <Grid container p={2} spacing={1} height={"100%"}>
                <Grid item xs={7}>
                    <Paper>
                        <video 
                            src={downloadedVideos[focusedLesson]}
                            height={"100%"}
                            width={"100%"}
                            controls
                            onTimeUpdate={(e) => {
                                // @ts-ignore
                                const progress = currency(e.target.currentTime).divide(e.target.duration).value
                                
                                if (progress > 0) {
                                    handleSaveProgress(focusedLesson, progress)
                                }
                            }}
                        />
                    </Paper>
                </Grid>
                <Grid item xs={5}>
                    <Paper style={{padding: 5, marginBottom: 5}}>
                        <Typography textAlign="center" variant="h6">
                            {training?.data?.name}
                        </Typography>
                    </Paper>
                    <Paper>
                        <StyledTable 
                            columns={[
                                { field: 'actions', type: 'actions', width: 50, sortable: false, getActions: (params: GridRowParams) => [
                                    <GridActionsCellItem 
                                        icon={
                                            params.row.progress === 1 
                                            ? <CheckOutlined /> 
                                            : <PlayCircleOutline/>
                                        } 
                                        onClick={() => {
                                            setFocusedLesson(params.row.id)
                                        }} 
                                        label="Assistir" 
                                    />
                                ] },
                                { field: 'name', flex: 1, type: 'string', headerName: 'Nome', sortable: false, filterable: false, disableColumnMenu: true },
                                { field: 'progress', headerName: 'Progresso', flex: 1, sortable: false, filterable: false, type: 'string', renderCell: ({value}) => {
                                    return (
                                        <div style={{display: 'flex', flex: 1}}>
                                            <LinearProgressWithLabel value={value * 100} />
                                        </div>
                                    )
                                },
                                display: 'flex',
                            }
                            ]}
                            getRowClassName={params => params.id === focusedLesson ? 'selected' : 'not-selected'}
                            rows={(training?.data?.lessons || []).map((lesson: {user_lesson_watched: Array<{progress: number}>}) => ({
                                ...lesson,
                                progress: Number(lesson?.user_lesson_watched?.[0]?.progress || 0)
                            }))}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </Layout>
    )
}