import { useFormik } from "formik"
import { Button, CurrencyInput, Grid, Input, NumericInput, Switch, Typography } from "../../../components"
import currency from 'currency.js'
import { formatMoney, nl2br } from "@bussola-viagens/utils"
import { useContext, useEffect, useMemo, useState } from "react"
import { PriceSetting } from '@bussola-viagens/constants'
import axios from 'axios'
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong'
import { AuthContext } from "../../../contexts"
import { Alert, AlertTitle } from "@mui/material"

interface AeroplanCalculatorProps {
    configuration: {
        prices: PriceSetting[],
        flight_in_next_seven_days: number
        comments?: string
    }
}

export const AeroplanCalculator = ({ configuration }: AeroplanCalculatorProps) => {
    const {me} = useContext(AuthContext)
    const [currencies, setCurrencies] = useState<Array<{value: string, label: string, rate: number}>>([])

    const formik = useFormik({
        initialValues: {
            pax: 1,
            miles: '',
            fees: '',
            extra: '',
            currency: 'CAD',
            fly_this_week: false
        },
        onSubmit: () => {}
    })

    const costPerK = useMemo(() => {
        if (formik.values.fly_this_week) {
            return configuration.flight_in_next_seven_days
        }

        const kPerPax = formik.values.pax ? currency(Number(formik.values.miles)).divide(1000).divide(Number(formik.values.pax)).value : 1
        
        const price = configuration.prices.find(p => {
            if (!p.to && currency(Number(p.from)).divide(1000).value <= kPerPax) {
                return true
            }

            return currency(Number(p.from)).divide(1000).value <= kPerPax && currency(Number(p.to)).divide(1000).value >= kPerPax
        })

        return price?.price || 0
    }, [configuration.flight_in_next_seven_days, configuration.prices, formik.values.fly_this_week, formik.values.miles, formik.values.pax])
    
    const fees = useMemo(() => {
        const rate = currencies.find(c => c.value === formik.values.currency)?.rate

        return currency(Number(formik.values.fees)).multiply(Number(rate || 1)).value
    }, [currencies, formik.values.currency, formik.values.fees])

    const totalCost = useMemo(() => {
        const k = currency(formik.values.miles).divide(1000).value
        const cost = currency(k).multiply(costPerK).value

        return currency(cost).add(fees || 0).add(formik.values.extra || 0).value
    }, [costPerK, formik.values.extra, fees, formik.values.miles])

    useEffect(() => {
        (async () => {
            const { data } = await axios.get(`https://api.frankfurter.app/latest?from=BRL`)

            const rates = Object.entries(data.rates).map(([key, value]) => {
                const rate = currency(1, {precision: 4}).divide(Number(value)).multiply(1.07).value

                return {
                    value: key,
                    label: `${key} (${formatMoney(rate)})`,
                    rate: Number(rate)
                }
            })

            setCurrencies(rates)
        })()
    }, [])

    return (
        <Grid container spacing={1}>
             {configuration.comments && (<Grid item xs={12} mb={2}>
                <Alert icon={false} color="info">
                <AlertTitle>Observações:</AlertTitle>
                    <span dangerouslySetInnerHTML={{__html: nl2br(configuration.comments)}} />
                </Alert>
            </Grid>)}
            <Grid item xs={12}>
                <Input 
                    name='pax'
                    label="Passageiros"
                    onChange={(event) => {
                        formik.setFieldValue('pax', event.target.value.replace(/\D/ig, ''))
                    }}
                    value={formik.values.pax}
                />
            </Grid>
            <Grid item xs={12}>
                <NumericInput 
                    name='miles'
                    label="Milhas"
                    onChange={formik.handleChange}
                    value={formik.values.miles}
                />
            </Grid>
            <Grid item xs={4}>
                <Input 
                    name="currency"
                    label="Moeda"
                    select
                    options={currencies}
                    value={formik.values.currency}
                    onChange={formik.handleChange}
                    helperText="Valor oficial + 7%"
                />
            </Grid>
            <Grid item xs={8}>
                <CurrencyInput
                    label={`Taxas (${formik.values.currency})`}
                    onChangeValue={(_, originalValue) => {
                        formik.setFieldValue('fees', originalValue, true)
                    }}
                    value={formik.values.fees}
                />
            </Grid>
            <Grid item xs={12}>
                <CurrencyInput
                    label="Extras (R$)"
                    onChangeValue={(_, originalValue) => {
                        formik.setFieldValue('extra', originalValue, true)
                    }}
                    value={formik.values.extra}
                    helperText='Utilize este campo para custos extras como bagagens e marcação de assentos.'
                />
            </Grid>
            {configuration.flight_in_next_seven_days ? (<Grid item xs={12}>
                <Switch
                    label="O voo é dentro dos próximos 7 dias?"
                    onChange={() => {
                        formik.setFieldValue('fly_this_week', !formik.values.fly_this_week)
                    }}
                    value={formik.values.fly_this_week}
                />
            </Grid>) : null}
            <Grid item xs={12} mt={2}>
                <Typography textAlign="center">
                    ({currency(formik.values.miles).divide(1000).value} x {formatMoney(costPerK)}) {fees ? `+ ${formatMoney(Number(fees))}` : ''} {formik.values.extra ? `+ ${formatMoney(Number(formik.values.extra))}` : ''} = <strong>{formatMoney(totalCost)}</strong>
                </Typography>
            </Grid>
            {me?.group.group_module?.is_offer_enabled && (<Grid item xs={12} mt={4} textAlign="center">
                <Button 
                    startIcon={<ReceiptLongIcon/>}
                    href={`/meus-orcamentos/novo?flight_price=${totalCost}`}
                >
                    Gerar Orçamento 
                </Button>
            </Grid>)}
        </Grid>
    )
}