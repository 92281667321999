import { formatDateTime } from '@bussola-viagens/utils'
import { CreateGroupDocumentSchema } from '@bussola-viagens/validations'
import { GridActionsCellItem, GridDeleteIcon, GridRowParams } from '@mui/x-data-grid'
import { useFormik } from 'formik'
import { enqueueSnackbar } from 'notistack'
import {Fragment} from 'react'
import { Button, FileUploader, Grid, Input, Loader, LoaderContainer, Modal, Paper, Table } from "../../../../components"
import { useAPI, useToggle } from "../../../../hooks"
import DownloadIcon from '@mui/icons-material/Download';

interface DocumentsProps {
    groupId: string
}

export const Documents = ({groupId}: DocumentsProps) => {
    const [modal, modalControls] = useToggle(false)
    const [group, refetchGroup] = useAPI(`/group/${groupId}`)
    const [createdDocument, createDocument] = useAPI({
        url: `/group/${groupId}/document`,
        method: 'POST'
    }, {
        manual: true
    })
    const [deletedDocument, deleteDocument] = useAPI({
        url: `/group/${groupId}/document/`,
        method: 'DELETE'
    }, {
        manual: true
    })

    const [dowloadedFile, downloadFile] = useAPI({
        method: 'GET',
    }, {
        manual: true
    })

    const form = useFormik({
        initialValues: {
            name: '',
            file: null
        },
        validationSchema: CreateGroupDocumentSchema,
        onSubmit: async (value, helpers) => {
            const formData = new FormData();
            
            formData.append('name', value.name)
            formData.append('file', (value as unknown as { file: File }).file)
            
            const {status} = await createDocument({
                method: 'POST',
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })

            if (status <= 299) {
                enqueueSnackbar('Documento adicionado com sucesso!', { variant: 'success' })
                refetchGroup()
                helpers.resetForm()
                modalControls.setFalse()
            }
        },
    })

    if (group.loading || createdDocument.loading || deletedDocument.loading || dowloadedFile.loading) {
        return (
            <LoaderContainer>
                <Loader />
            </LoaderContainer>
        )
    }

    return (
        <Fragment>
        <Grid container spacing={1}>
            <Grid item xs={12} textAlign="right">
                <Button onClick={modalControls.setTrue}>
                    Adicionar
                </Button>
            </Grid>
            <Grid item xs={12}>
                <Paper>
                    <Table 
                        columns={[
                            { field: 'created_at', flex: 1, type: 'dateTime', headerName: 'Data de Criação', valueGetter: (value) => new Date(value), valueFormatter: formatDateTime },
                            { field: 'creator', flex: 1, type: 'string', headerName: 'Autor', valueGetter: (_, row) => row.file.creator.name},
                            { field: 'name', flex: 1, type: 'string', headerName: 'Nome' },
                            { field: 'actions', type: 'actions', getActions: (params: GridRowParams) => [
                                <GridActionsCellItem 
                                    icon={<DownloadIcon />} 
                                    onClick={() => {
                                        downloadFile({
                                            url: `/file/${params.row.file_id}`,
                                            responseType: 'blob'
                                        }).then((res) => {
                                            if (res.status === 200) {
                                                const blobUrl = URL.createObjectURL(res.data);

                                                window.open(blobUrl, '_blank')
                                            }
                                        })
                                    }} 
                                    label="Fazer Download" 
                                />,
                                <GridActionsCellItem 
                                    icon={<GridDeleteIcon />} 
                                    onClick={async () => {
                                        const {status} = await deleteDocument({
                                            url: `/group/${groupId}/document/${params.id}`,
                                            method: 'DELETE'
                                        })

                                        if (status <= 299) {
                                            enqueueSnackbar('Documento excluído com sucesso!', {variant: 'success'})
                                            refetchGroup()
                                        }
                                    }} 
                                    label="Fazer Download" 
                                />,
                            ] }
                        ]}
                        rows={group.data.group_document || []}
                    />
                </Paper>
            </Grid>
        </Grid> 
            {modal && (<Modal
                title='Novo Documento'
                open={modal}
                onConfirm={form.handleSubmit}
                onClose={modalControls.setFalse}
                confirmLabel="Adicionar"
                bodyCustomCss={{
                    width: 600
                }}
            >
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Input 
                            name="name"
                            label="Nome"
                            value={form.values.name}
                            onChange={form.handleChange}
                            error={!!form.touched.name && !!form.errors.name}
                            helperText={form.touched.name && form.errors.name}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FileUploader 
                            label="Arquivo"
                            onChange={(value) => {
                                form.setFieldValue('file', value, true)
                            }}
                            value={form.values.file}
                            inputProps={{
                                accept: 'image/*, .pdf'
                            }}
                            helperText={form.touched.file && form.errors.file}
                            error={!!form.touched.file && !!form.errors.file}
                        />
                    </Grid>
                </Grid>
            </Modal>)}
        </Fragment>
    )
}