import { useContext } from "react"
import { useParams } from "react-router-dom"
import { Grid, Layout, Loader, LoaderContainer, Profile } from "../../components"
import { AuthContext } from "../../contexts"

export const MyProfilePage = () => {
    const {me} = useContext(AuthContext)

    if (!me?.id) {
        return (
            <LoaderContainer>
                <Loader />
            </LoaderContainer>
        )
    }

    return (
        <Layout>
            <Grid container justifyContent={'center'} padding={4}>
                <Grid item xs={10} sm={8} md={6} lg={4}>
                    <Profile userId={me?.id} />
                </Grid>
            </Grid>
        </Layout>
    )
}