"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateOfferSchema = exports.CreateSolicitationReplySchema = exports.CreateGroupSolicitationSchema = exports.UpdateGroupPromotionSchema = exports.CreatePromotionMediaSchema = exports.UpdatePromotionSchema = exports.CreatePromotionSchema = exports.CreateMediaSchema = exports.UpdateMediaLibrarySchema = exports.CreateMediaLibrarySchema = exports.CreateLessonSchema = exports.UpdateTrainingSchema = exports.CreateTrainingSchema = exports.CreateGroupDocumentSchema = exports.UpdateGroupCompanyConfigurationSchema = exports.FromToPricesSchema = exports.UpdateGroupMediaLibrarySchema = exports.UpdateGroupTrainingSchema = exports.UpdateGroupSchema = exports.CreateGroupSchema = exports.CreateUserSchema = exports.UpdateProfileSchema = exports.SignInSchema = void 0;
const Yup = __importStar(require("yup"));
const database_1 = require("@bussola-viagens/database");
const lodash_1 = require("lodash");
const REQUIRED_FIELD = 'Este campo é obrigatório';
const MUST_BE_VALID_EMAIL = 'Este campo deve ser um email válido';
exports.SignInSchema = Yup.object({
    password: Yup.string().required(REQUIRED_FIELD),
    email: Yup.string().email(MUST_BE_VALID_EMAIL).required(REQUIRED_FIELD),
});
exports.UpdateProfileSchema = Yup.object({
    role: Yup.string().oneOf([database_1.USER_ROLE.ADMIN, database_1.USER_ROLE.USER]).optional(),
    active: Yup.boolean().optional(),
    password: Yup.string().test((value, schema) => {
        if (!value) {
            return true;
        }
        if ((value === null || value === void 0 ? void 0 : value.length) < 8) {
            return schema.createError({
                message: 'Deve conter no mínimo 8 caracteres'
            });
        }
        if (!(value === null || value === void 0 ? void 0 : value.match(/[a-z]+/))) {
            return schema.createError({
                message: 'Deve conter no mínimo uma letra minúscula'
            });
        }
        if (!(value === null || value === void 0 ? void 0 : value.match(/[A-Z]+/))) {
            return schema.createError({
                message: 'Deve conter no mínimo uma letra maiúscula'
            });
        }
        if (!(value === null || value === void 0 ? void 0 : value.match(/\d+/))) {
            return schema.createError({
                message: 'Deve conter um número'
            });
        }
        return true;
    }),
    password_confirmation: Yup.string().oneOf([Yup.ref('password')], 'As senhas devem ser iguais').when('password', ([value], schema) => {
        if (!!value) {
            return schema.required(REQUIRED_FIELD);
        }
        return schema;
    }),
});
exports.CreateUserSchema = Yup.object({
    name: Yup.string().required(REQUIRED_FIELD),
    email: Yup.string().email(MUST_BE_VALID_EMAIL).required(REQUIRED_FIELD),
    role: Yup.string().oneOf([database_1.USER_ROLE.ADMIN, database_1.USER_ROLE.USER]).required(REQUIRED_FIELD),
    password: Yup.string().test((value, schema) => {
        if (!value) {
            return true;
        }
        if ((value === null || value === void 0 ? void 0 : value.length) < 8) {
            return schema.createError({
                message: 'Deve conter no mínimo 8 caracteres'
            });
        }
        if (!(value === null || value === void 0 ? void 0 : value.match(/[a-z]+/))) {
            return schema.createError({
                message: 'Deve conter no mínimo uma letra minúscula'
            });
        }
        if (!(value === null || value === void 0 ? void 0 : value.match(/[A-Z]+/))) {
            return schema.createError({
                message: 'Deve conter no mínimo uma letra maiúscula'
            });
        }
        if (!(value === null || value === void 0 ? void 0 : value.match(/\d+/))) {
            return schema.createError({
                message: 'Deve conter um número'
            });
        }
        return true;
    }),
    password_confirmation: Yup.string().oneOf([Yup.ref('password')], 'As senhas devem ser iguais').when('password', ([value], schema) => {
        if (!!value) {
            return schema.required(REQUIRED_FIELD);
        }
        return schema;
    }),
});
exports.CreateGroupSchema = Yup.object({
    name: Yup.string().required(REQUIRED_FIELD),
    users: Yup.array().of(Yup.string()).optional()
});
exports.UpdateGroupSchema = Yup.object({
    name: Yup.string().required(REQUIRED_FIELD),
    users: Yup.array().of(Yup.string()).optional()
});
exports.UpdateGroupTrainingSchema = Yup.object({
    trainings: Yup.array().of(Yup.string()).optional()
});
exports.UpdateGroupMediaLibrarySchema = Yup.object({
    media_libraries: Yup.array().of(Yup.string()).optional()
});
exports.FromToPricesSchema = Yup.array().of(Yup.object({
    from: Yup.number().test({
        test: (value, context) => {
            // @ts-ignore
            const index = context.options.index;
            // @ts-ignore
            const actualPrices = context.options.context.prices;
            if (index === 0 && value !== 1) {
                return context.createError({
                    path: context.path,
                    message: 'Este campo deve ser 1'
                });
            }
            const lastItem = (0, lodash_1.get)(actualPrices, index - 1);
            const item = (0, lodash_1.get)(actualPrices, index);
            if (index > 0 && Number(item.from) !== Number(lastItem.to) + 1) {
                return context.createError({
                    path: context.path,
                    message: `Este campo deve ser ${lastItem.to ? Number(lastItem.to) + 1 : 'maior que o anterior'}`
                });
            }
            return true;
        }
    }).required(REQUIRED_FIELD),
    to: Yup.string().test({
        test: (value, context) => {
            // @ts-ignore
            const index = context.options.index;
            // @ts-ignore
            const actualPrices = context.options.context.prices;
            if (!value && index !== actualPrices.length - 1) {
                return context.createError({
                    path: context.path,
                    message: REQUIRED_FIELD
                });
            }
            return true;
        }
    }).nullable(),
    price: Yup.number().required(REQUIRED_FIELD)
})).min(1, REQUIRED_FIELD);
exports.UpdateGroupCompanyConfigurationSchema = Yup.object({
    prices: exports.FromToPricesSchema,
    flight_in_next_seven_days: Yup.string().optional(),
    comments: Yup.string().optional()
});
exports.CreateGroupDocumentSchema = Yup.object({
    name: Yup.string().required(REQUIRED_FIELD),
    file: Yup.mixed()
        .test({
        test: (v) => {
            const value = v;
            if (!value || !value.name) {
                return false;
            }
            return true;
        },
        message: REQUIRED_FIELD
    }).nullable()
});
exports.CreateTrainingSchema = Yup.object({
    name: Yup.string().required(REQUIRED_FIELD),
});
exports.UpdateTrainingSchema = Yup.object({
    name: Yup.string().required(REQUIRED_FIELD),
});
exports.CreateLessonSchema = Yup.object({
    name: Yup.string().required(REQUIRED_FIELD),
    file: Yup.mixed()
        .test({
        test: (v) => {
            const value = v;
            if (!value || !value.name) {
                return false;
            }
            return true;
        },
        message: REQUIRED_FIELD
    }).nullable()
});
exports.CreateMediaLibrarySchema = Yup.object({
    name: Yup.string().required(REQUIRED_FIELD),
});
exports.UpdateMediaLibrarySchema = Yup.object({
    name: Yup.string().required(REQUIRED_FIELD),
});
exports.CreateMediaSchema = Yup.object({
    name: Yup.string().required(REQUIRED_FIELD),
    file: Yup.mixed()
        .test({
        test: (v) => {
            const value = v;
            if (!value || !value.name) {
                return false;
            }
            return true;
        },
        message: REQUIRED_FIELD
    }).nullable()
});
exports.CreatePromotionSchema = Yup.object({
    name: Yup.string().required(REQUIRED_FIELD),
    description: Yup.string().required(REQUIRED_FIELD),
});
exports.UpdatePromotionSchema = Yup.object({
    name: Yup.string().required(REQUIRED_FIELD),
    description: Yup.string().required(REQUIRED_FIELD),
    active: Yup.boolean().required(REQUIRED_FIELD)
});
exports.CreatePromotionMediaSchema = Yup.object({
    file: Yup.mixed()
        .test({
        test: (v) => {
            const value = v;
            if (!value || !value.name) {
                return false;
            }
            return true;
        },
        message: REQUIRED_FIELD
    }).nullable()
});
exports.UpdateGroupPromotionSchema = Yup.object({
    promotions: Yup.array().of(Yup.string()).optional()
});
exports.CreateGroupSolicitationSchema = Yup.object({
    title: Yup.string().required(REQUIRED_FIELD),
    description: Yup.string().required(REQUIRED_FIELD)
});
exports.CreateSolicitationReplySchema = Yup.object({
    description: Yup.string().required(REQUIRED_FIELD)
});
exports.CreateOfferSchema = Yup.object({
    has_flight: Yup.boolean().required(REQUIRED_FIELD),
    has_hotel: Yup.boolean().required(REQUIRED_FIELD),
    buyer_name: Yup.string().required(REQUIRED_FIELD),
    payment_method: Yup.string().required(REQUIRED_FIELD),
    cabin: Yup.string().when('has_flight', {
        is: true,
        then: schema => schema.required(REQUIRED_FIELD),
        otherwise: schema => schema.optional()
    }),
    flight_photo: Yup.mixed().when('has_flight', {
        is: true,
        then: schema => schema
            .test({
            test: (v) => {
                const value = v;
                if (!value || !value.name) {
                    return false;
                }
                return true;
            },
            message: REQUIRED_FIELD
        }).nullable(),
        otherwise: schema => schema.optional()
    }),
    checked_luggages: Yup.string().when('has_flight', {
        is: true,
        then: schema => schema.required(REQUIRED_FIELD),
        otherwise: schema => schema.optional()
    }),
    flight_extra_service: Yup.string().optional(),
    flight_price: Yup.string().when('has_flight', {
        is: true,
        then: schema => schema.required(REQUIRED_FIELD),
        otherwise: schema => schema.optional()
    }),
    hotel_photos: Yup.array().of(Yup.mixed()
        .test({
        test: (v) => {
            const value = v;
            if (!value || !value.name) {
                return false;
            }
            return true;
        },
        message: REQUIRED_FIELD
    }).nullable())
        .when('has_hotel', {
        is: true,
        then: schema => schema.min(1).required(REQUIRED_FIELD),
        otherwise: schema => schema.optional()
    }),
    hotel_name: Yup.string().when('has_hotel', {
        is: true,
        then: schema => schema.required(REQUIRED_FIELD),
        otherwise: schema => schema.optional()
    }),
    hotel_address: Yup.string().when('has_hotel', {
        is: true,
        then: schema => schema.required(REQUIRED_FIELD),
        otherwise: schema => schema.optional()
    }),
    hotel_description: Yup.string().when('has_hotel', {
        is: true,
        then: schema => schema.required(REQUIRED_FIELD),
        otherwise: schema => schema.optional()
    }),
    hotel_rules: Yup.string().optional(),
    hotel_price: Yup.string().when('has_hotel', {
        is: true,
        then: schema => schema.required(REQUIRED_FIELD),
        otherwise: schema => schema.optional()
    }),
});
