import { GridActionsCellItem, GridRowParams } from "@mui/x-data-grid"
import { Button, Grid, Input, Layout, Loader, LoaderContainer, Modal, Paper, Table } from "../../components"
import { useAPI, useToggle } from "../../hooks";
import { enqueueSnackbar } from "notistack";
import { formatDateTime } from "@bussola-viagens/utils";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { CreateMediaLibrarySchema } from "@bussola-viagens/validations";

export const MediaLibrariesPage = () => {
    const navigate = useNavigate()
    const [modal, modalControls] = useToggle(false)
    const [mediaLibraries, refetchMediaLibraries] = useAPI(`/media-library`)
    const [createdMediaLibrary, createMediaLibrary] = useAPI({}, {manual: true})

    const form = useFormik({
        initialValues: {
            name: ''
        },
        validationSchema: CreateMediaLibrarySchema,
        onSubmit: async (data, helpers) => {
            const { status } = await createMediaLibrary({
                url: `/media-library`,
                data,
                method: 'POST'
            })

            if (status <= 299) {
                enqueueSnackbar('Banco de mídias criado com sucesso!', { variant: 'success' })
                modalControls.setFalse()
                helpers.resetForm()
                refetchMediaLibraries()
            }
        }
    })

    if (createdMediaLibrary.loading || mediaLibraries.loading) {
        return (
            <LoaderContainer>
                <Loader />
            </LoaderContainer>
        )
    }

    return (
        <Layout>
            <Grid container p={1} justifyContent="center" spacing={1}>
                <Grid item xs={10} textAlign="right">
                    <Button
                        onClick={modalControls.setTrue}
                    >
                        Adicionar
                    </Button>
                </Grid>
                <Grid item xs={10}>
                    <Paper>
                        <Table 
                            columns={[
                                { field: 'created_at', flex: 1, type: 'dateTime', headerName: 'Data de Criação', valueGetter: (value) => new Date(value), valueFormatter: formatDateTime },
                                { field: 'name', flex: 1, type: 'string', headerName: 'Nome' },
                                { field: 'media', flex: 1, type: 'number', headerName: 'Mídias', valueGetter: (value: Array<string>) => value.length },
                                { field: 'actions', type: 'actions', getActions: (params: GridRowParams) => [
                                    <GridActionsCellItem 
                                        icon={<VisibilityIcon />} 
                                        onClick={() => {
                                            navigate(`/banco-de-midias/${params.id}`)
                                        }} 
                                        label="Ver Detalhes" 
                                    />,
                                ] }
                            ]}
                            rows={mediaLibraries.data || []}
                        />
                    </Paper>
                </Grid>
            </Grid>
            {modal && (<Modal
                title="Novo Banco de Mídias"
                open={modal}
                onConfirm={form.handleSubmit}
                confirmLabel="Adicionar"
                bodyCustomCss={{
                    width: 400
                }}
                onClose={modalControls.setFalse}
            >
                <Grid container>
                    <Grid item xs={12}>
                        <Input 
                            name="name"
                            label="Nome"
                            value={form.values.name}
                            onChange={form.handleChange}
                            error={!!form.touched.name && !!form.errors.name}
                            helperText={form.touched.name && form.errors.name}
                        />
                    </Grid>
                </Grid>
            </Modal>)}
        </Layout>
    )
}