"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SOLICITATION_STATUS = exports.PROGRAMS = exports.PROGRAM = exports.ROLES = void 0;
const database_1 = require("@bussola-viagens/database");
exports.ROLES = [
    { value: database_1.USER_ROLE.ADMIN, label: 'Administrador' },
    { value: database_1.USER_ROLE.USER, label: 'Usuário' },
];
var PROGRAM;
(function (PROGRAM) {
    PROGRAM["SMILES_BR"] = "SMILES_BR";
    PROGRAM["SMILES_AR"] = "SMILES_AR";
    PROGRAM["LATAMPASS_BR"] = "LATAMPASS_BR";
    PROGRAM["LATAMPASS_AR"] = "LATAMPASS_AR";
    PROGRAM["AADVANTAGE"] = "AADVANTAGE";
    PROGRAM["TUDO_AZUL"] = "TUDO_AZUL";
    PROGRAM["AZUL_INTERLINE"] = "AZUL_INTERLINE";
    PROGRAM["AZUL_VIAGENS"] = "AZUL_VIAGENS";
    PROGRAM["IBERIA_PLUS"] = "IBERIA_PLUS";
    PROGRAM["TAP"] = "TAP";
    PROGRAM["AIR_EUROPA"] = "AIR_EUROPA";
    PROGRAM["LIFEMILES"] = "LIFEMILES";
    PROGRAM["FLYING_BLUE"] = "FLYING_BLUE";
    PROGRAM["CONNECT_MILES"] = "CONNECT_MILES";
    PROGRAM["AEROPLAN"] = "AEROPLAN";
    PROGRAM["TUDO_AZUL_V2"] = "TUDO_AZUL_V2";
})(PROGRAM || (exports.PROGRAM = PROGRAM = {}));
exports.PROGRAMS = [
    { value: PROGRAM.SMILES_BR, label: 'Smiles BR' },
    { value: PROGRAM.SMILES_AR, label: 'Smiles AR' },
    { value: PROGRAM.LATAMPASS_BR, label: 'Latam BR' },
    { value: PROGRAM.LATAMPASS_AR, label: 'Latam AR' },
    { value: PROGRAM.AADVANTAGE, label: 'AAdvantage' },
    { value: PROGRAM.TUDO_AZUL, label: 'Tudo Azul' },
    { value: PROGRAM.AZUL_INTERLINE, label: 'Azul Interline' },
    { value: PROGRAM.AZUL_VIAGENS, label: 'Azul Viagens' },
    { value: PROGRAM.IBERIA_PLUS, label: 'Iberia Plus' },
    { value: PROGRAM.TAP, label: 'TAP' },
    { value: PROGRAM.LIFEMILES, label: 'Lifemiles' },
    { value: PROGRAM.FLYING_BLUE, label: 'Flying Blue' },
    { value: PROGRAM.AIR_EUROPA, label: 'Air Europa SUMA' },
    { value: PROGRAM.CONNECT_MILES, label: 'Connect Miles' },
    { value: PROGRAM.AEROPLAN, label: 'Aeroplan' },
    { value: PROGRAM.TUDO_AZUL_V2, label: 'Tudo Azul (Menos Milhas)' }
];
exports.SOLICITATION_STATUS = [
    { value: database_1.$Enums.SOLICITATION_STATUS.PENDING, label: 'Pendente', chipColor: 'default', },
    { value: database_1.$Enums.SOLICITATION_STATUS.FINISHED, label: 'Finalizado', chipColor: 'success', },
    { value: database_1.$Enums.SOLICITATION_STATUS.CANCELED, label: 'Cancelado', chipColor: 'error', },
];
