import { GridActionsCellItem, GridDeleteIcon, GridRowParams, GridViewColumnIcon } from "@mui/x-data-grid"
import { Fragment, useContext, useState } from "react"
import { Button, Grid, Input, Layout, Loader, LoaderContainer, Modal, Paper, Table, Typography } from "../../components"
import { AuthContext } from "../../contexts"
import DownloadIcon from '@mui/icons-material/Download';
import { useAPI, useToggle } from "../../hooks";
import { enqueueSnackbar } from "notistack";
import { formatDateTime } from "@bussola-viagens/utils";
import { Navigate, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { CreateGroupSolicitationSchema, CreateTrainingSchema } from "@bussola-viagens/validations";
import { $Enums, group } from "@bussola-viagens/database";
import { SOLICITATION_STATUS } from "@bussola-viagens/constants";
import { Chip, ChipOwnProps } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import DoneAllIcon from '@mui/icons-material/DoneAll';

export const MySolicitationsPage = () => {
    const [modal, modalControls] = useToggle(false)
    const [updateConfirmationModal, updateConfirmationModalControls] = useToggle(false)
    const navigate = useNavigate()
    const [solicitations, refetchSolicitations] = useAPI(`/solicitation`)
    const [created, create] = useAPI({}, {manual: true})
    const {me} = useContext(AuthContext)
    const [updateStatus, setUpdateStatus] = useState<$Enums.SOLICITATION_STATUS>($Enums.SOLICITATION_STATUS.CANCELED)
    const [updateStatusId, setUpdateStatusId] = useState<string | undefined>()

    const form = useFormik({
        initialValues: {
            title: '',
            description: '',
        },
        validationSchema: CreateGroupSolicitationSchema,
        onSubmit: async (data, helpers) => {
            const { status } = await create({
                url: `/group/${me?.group.id}/solicitation`,
                data,
                method: 'POST'
            })

            if (status <= 299) {
                enqueueSnackbar('Pedido criado com sucesso!', { variant: 'success' })
                modalControls.setFalse()
                helpers.resetForm()
                refetchSolicitations()
            }
        }
    })

    if (!me?.group.group_module?.is_solicitation_enabled) {
        return (
            <Navigate to="/home" />
        )
    }

    if (created.loading || solicitations.loading) {
        return (
            <LoaderContainer>
                <Loader />
            </LoaderContainer>
        )
    }
    
    return (
        <Layout>
            <Grid container p={1} justifyContent="center" spacing={1}>
                <Grid item xs={10}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} textAlign="right">
                            <Button onClick={modalControls.setTrue}>
                                Adicionar
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper>
                                <Table 
                                    columns={[
                                        { field: 'created_at', flex: 1, type: 'dateTime', headerName: 'Data de Criação', valueGetter: (value) => new Date(value), valueFormatter: formatDateTime },
                                        { field: 'title', flex: 1, type: 'string', headerName: 'Nome' },
                                        { field: 'group', flex: 1, type: 'string', headerName: 'Grupo', valueGetter: (value: group) => value.name },
                                        { field: 'status', flex: 1, type: 'singleSelect', valueOptions: SOLICITATION_STATUS, headerName: 'Status', renderCell: ({ value, formattedValue, row }) => {
                                            const color = SOLICITATION_STATUS.find(s => s.value === row.status)?.chipColor as ChipOwnProps['color']

                                            return (
                                                <Chip label={formattedValue} color={color} />
                                            )
                                        } },
                                        { field: 'actions', type: 'actions', getActions: (params: GridRowParams) => [
                                            <GridActionsCellItem 
                                                icon={<VisibilityIcon />} 
                                                onClick={() => {
                                                    navigate(`/meus-pedidos/${params.id}`)
                                                }} 
                                                label="Ver Detalhes" 
                                            />,
                                            <GridActionsCellItem 
                                                icon={<CloseIcon />} 
                                                onClick={() => {
                                                    setUpdateStatus($Enums.SOLICITATION_STATUS.CANCELED)
                                                    setUpdateStatusId(params.id as string)
                                                    updateConfirmationModalControls.setTrue()
                                                }} 
                                                label="Cancelar" 
                                                showInMenu
                                                disabled={params.row.status !== $Enums.SOLICITATION_STATUS.PENDING}
                                            />,
                                            <GridActionsCellItem 
                                                icon={<DoneAllIcon />} 
                                                onClick={() => {
                                                    setUpdateStatus($Enums.SOLICITATION_STATUS.FINISHED)
                                                    setUpdateStatusId(params.id as string)
                                                    updateConfirmationModalControls.setTrue()
                                                }} 
                                                label="Finalizar" 
                                                showInMenu
                                                disabled={params.row.status !== $Enums.SOLICITATION_STATUS.PENDING}
                                            />
                                        ] }
                                    ]}
                                    rows={solicitations.data || []}
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {
                modal && (
                    <Modal
                        title="Nova Solicitação"
                        open={modal}
                        onClose={modalControls.setFalse}
                        confirmLabel="Adicionar"
                        onConfirm={form.handleSubmit}
                    >
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Input 
                                    name="title"
                                    label="Título"
                                    value={form.values.title}
                                    onChange={form.handleChange}
                                    error={!!form.touched.title && !!form.errors.title}
                                    helperText={form.touched.title && form.errors.title}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Input 
                                    name="description"
                                    label="Descreva com detalhes o seu pedido..."
                                    multiline
                                    maxRows={4}
                                    rows={4}
                                    value={form.values.description}
                                    onChange={form.handleChange}
                                    error={!!form.touched.description && !!form.errors.description}
                                    helperText={form.touched.description && form.errors.description}
                                />
                            </Grid>
                        </Grid>
                    </Modal>
                )
            }
            {
                updateConfirmationModal && (
                    <Modal
                        open={updateConfirmationModal}
                        onClose={updateConfirmationModalControls.setFalse}
                        confirmLabel="Confirmar"
                        onConfirm={async () => {
                            const { status } = await create({
                                url: `/solicitation/${updateStatusId}/status/${updateStatus}`,
                                method: 'PUT'
                            })

                            if (status <= 299) {
                                refetchSolicitations()
                                setUpdateStatusId(undefined)
                                updateConfirmationModalControls.setFalse()

                                enqueueSnackbar('Pedido atualizado com sucesso!', {variant: 'success'})
                            }
                        }}
                    >
                        <Fragment>
                            <Typography>Você tem certeza que deseja atualizar o status deste pedido?</Typography>
                            <Typography>Esta ação é <strong>irreversível</strong> e não poderá ser desfeita.</Typography>
                        </Fragment>
                    </Modal>
                )
            }
        </Layout>
    )
}