import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import MuiSwitch, {SwitchProps} from '@mui/material/Switch';

export function Switch(props: SwitchProps & {label?: string}) {
  return (
    <FormGroup style={props.style}>
      <FormControlLabel control={<MuiSwitch {...props} />} label={props.label} style={props.style} />
    </FormGroup>
  );
}

