import { formatDateTime } from '@bussola-viagens/utils'
import { CreateGroupDocumentSchema } from '@bussola-viagens/validations'
import { GridActionsCellItem, GridAddIcon, GridDeleteIcon, GridRowParams, GridSlots, GridToolbarContainer } from '@mui/x-data-grid'
import { useFormik } from 'formik'
import { enqueueSnackbar } from 'notistack'
import {Fragment, useEffect} from 'react'
import { Button, FileUploader, Grid, Input, Loader, LoaderContainer, Modal, Paper, Table } from "../../../../components"
import { useAPI, useToggle } from "../../../../hooks"
import DownloadIcon from '@mui/icons-material/Download';
import { group, group_promotion } from '@bussola-viagens/database'
import CheckIcon from '@mui/icons-material/Check';
import { useNavigate } from 'react-router-dom'

interface PromotionsProps {
    groupId: string
}

export const Promotions = ({groupId}: PromotionsProps) => {
    const navigate = useNavigate()
    const [group, refetchGroup] = useAPI(`/group/${groupId}`)
    const [promotions] = useAPI(`/promotion`)
    const [updatedGroup, updateGroup] = useAPI({}, { manual: true })

    const form = useFormik({
        initialValues: {
            promotions: '',
        },
        onSubmit: async (value, helper) => {
            const {status} = await updateGroup({
                url: `/group/${groupId}/promotion`,
                method: 'PUT',
                data: value
            })

            if (status <= 299) {
                enqueueSnackbar('Grupo atualizado com sucesso!', {variant: 'success'})
                await refetchGroup()
                helper.setStatus('draft')
            }
        },
        initialStatus: 'draft'
    })

    useEffect(() => {
        if (group.data && form.status === 'draft') {
            form.resetForm({
                values: {
                    promotions: group.data.group_promotions.map((item: group_promotion) => item.promotion_id)
                },
                status: 'initiated'
            })
        }
    }, [form, form.status, group])

    if (group.loading || promotions.loading || form.status === 'draft' || updatedGroup.loading) {
        return (
            <LoaderContainer>
                <Loader />
            </LoaderContainer>
        )
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                <Paper>
                    <Table 
                        rowSelection={true}
                        checkboxSelection
                        onRowSelectionModelChange={(value) => {
                            form.setFieldValue('promotions', value)
                        }}               
                        rowSelectionModel={form.values.promotions}   
                        columns={[
                            { field: 'created_at', flex: 1, type: 'dateTime', headerName: 'Data de Criação', valueGetter: (value) => new Date(value), valueFormatter: formatDateTime },
                            { field: 'name', flex: 1, type: 'string', headerName: 'Nome' },
                            { field: 'description', flex: 1, type: 'string', headerName: 'Description' },
                            { field: 'promotion_medias', flex: 1, type: 'number', headerName: 'Mídias', valueGetter: (value: Array<string>) => value.length },
                            { field: 'active', flex: 1, type: 'boolean', headerName: 'Ativo' },
                        ]}
                        rows={promotions.data || []}
                        slots={form.dirty ? {
                            toolbar: () => {
                                return (
                                    <GridToolbarContainer>
                                        <Button variant='text' startIcon={<CheckIcon />} onClick={() => form.handleSubmit()}>
                                        Salvar
                                        </Button>
                                    </GridToolbarContainer>
                                )
                            }
                          } : undefined}                  
                    />
                </Paper>
            </Grid>
        </Grid> 
    )
}
  