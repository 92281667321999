import { GridActionsCellItem, GridDeleteIcon, GridRowParams, GridViewColumnIcon } from "@mui/x-data-grid"
import { useContext } from "react"
import { Button, Grid, Input, Layout, Loader, LoaderContainer, Modal, Paper, Table } from "../../components"
import { AuthContext } from "../../contexts"
import DownloadIcon from '@mui/icons-material/Download';
import { useAPI, useToggle } from "../../hooks";
import { enqueueSnackbar } from "notistack";
import { formatDateTime } from "@bussola-viagens/utils";
import { Navigate, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { CreateTrainingSchema } from "@bussola-viagens/validations";

export const MyMediaLibrariesPage = () => {
    const navigate = useNavigate()
    const [mediaLibraries] = useAPI(`/media-library`)
    
    const { me } = useContext(AuthContext)

    if (!me?.group.group_module?.is_media_library_enabled) {
        return (
            <Navigate to="/home" />
        )
    }

    if (mediaLibraries.loading) {
        return (
            <LoaderContainer>
                <Loader />
            </LoaderContainer>
        )
    }

    return (
        <Layout>
            <Grid container p={1} justifyContent="center" spacing={1}>
                <Grid item xs={10}>
                    <Paper>
                        <Table 
                            columns={[
                                { field: 'created_at', flex: 1, type: 'dateTime', headerName: 'Data de Criação', valueGetter: (value) => new Date(value), valueFormatter: formatDateTime },
                                { field: 'name', flex: 1, type: 'string', headerName: 'Nome' },
                                { field: 'media', flex: 1, type: 'number', headerName: 'Mídias', valueGetter: (value: Array<string>) => value.length },
                                { field: 'actions', type: 'actions', getActions: (params: GridRowParams) => [
                                    <GridActionsCellItem 
                                        icon={<VisibilityIcon />} 
                                        onClick={() => {
                                            navigate(`/meus-bancos-de-midias/${params.id}`)
                                        }} 
                                        label="Ver Detalhes" 
                                    />,
                                ] }
                            ]}
                            rows={mediaLibraries.data || []}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </Layout>
    )
}