import { useNavigate, useParams } from "react-router-dom"
import { Grid, Layout, Loader, LoaderContainer, Paper, Input, Table, Typography, Button } from "../../../components"
import {useAPI} from '../../../hooks'
import {useFormik} from 'formik'
import { useCallback, useEffect, useMemo } from "react"
import { UpdateGroupSchema, UpdateGroupCompanyConfigurationSchema } from "@bussola-viagens/validations"
import { enqueueSnackbar } from "notistack"
import { Box, Tab, Tabs } from "@mui/material"
import { useTabs } from "@mui/base"
import { Programs } from "./Programs"
import { Settings } from "./Settings"
import { Documents } from "./Documents"
import { Trainings } from "./Trainings"
import { MediaLibraries } from "./MediaLibraries"
import { Promotions } from "./Promotions"

export const GroupPage = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const [group, refetchGroup] = useAPI(`/group/${id}`)
    const [updatedGroup, updateGroup] = useAPI({
        url: `/group/${id}`,
        method: 'PUT'
    }, {
        manual: true
    })

    const [users, refetchUsers] = useAPI(`/user`)

    const formik = useFormik({
        initialValues: {
            name: '',
            users: []
        },
        validationSchema: UpdateGroupSchema,
        onSubmit: async (values, helper) => {
            const { status } = await updateGroup({
                data: values
            })

            if (status <= 299) {
                enqueueSnackbar('Grupo atualizado com sucesso!', { variant: 'success' })
                helper.setStatus('draft')
                refetchGroup()
                refetchUsers()
            }
        },
        initialStatus: 'draft'
    })

    useEffect(() => {
        if (group.data && formik.status === 'draft') {
            formik.resetForm({
                values: {
                    name: group.data.name,
                    users: group.data.users.map((user: { id: string }) => user.id)
                },
                status: 'initiated'
            })
        }
    }, [group.data, formik])
    
    const tabs = useTabs({
        defaultValue: 'SETTINGS'
    })

    if (group.loading || users.loading || !id || formik.status === 'draft' || updatedGroup.loading) {
        return (
            <LoaderContainer>
                <Loader />
            </LoaderContainer>
        )
    }
    
    return (
        <Layout>
            <Grid container p={2} spacing={1} justifyContent={'center'}>
                <Grid item xs={12}>
                    <Paper>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={tabs.contextValue.value} onChange={tabs.contextValue.onSelected}>
                                <Tab label="Configurações" value="SETTINGS" />
                                <Tab label="Programas" value="PROGRAMS" />
                                <Tab label="Documentos" value="DOCUMENTS" />
                                <Tab label="Treinamentos" value="TRAININGS" />
                                <Tab label="Banco de Mídias" value="MEDIA_LIBRARIES" />
                                <Tab label="Promoções" value="PROMOTIONS" />
                            </Tabs>
                        </Box>
                    </Paper>
                </Grid>
                <Grid item xs={10}>
                    {
                        tabs.contextValue.value === 'SETTINGS' ? (
                            <Settings  
                                groupId={id} 
                            />
                        ) : null
                    }
                    {
                        tabs.contextValue.value === 'PROGRAMS' ? (
                            <Programs  
                                groupId={id} 
                            />
                        ) : null
                    }
                    {
                        tabs.contextValue.value === 'DOCUMENTS' ? (
                            <Documents  
                                groupId={id} 
                            />
                        ) : null
                    }
                    {
                        tabs.contextValue.value === 'TRAININGS' ? (
                            <Trainings  
                                groupId={id} 
                            />
                        ) : null
                    }
                    {
                        tabs.contextValue.value === 'MEDIA_LIBRARIES' ? (
                            <MediaLibraries  
                                groupId={id} 
                            />
                        ) : null
                    }
                    {
                        tabs.contextValue.value === 'PROMOTIONS' ? (
                            <Promotions  
                                groupId={id} 
                            />
                        ) : null
                    }
                </Grid>
            </Grid>
        </Layout>
    )
}