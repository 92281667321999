import { formatDateTime } from "@bussola-viagens/utils"
import { GridActionsCellItem, GridDeleteIcon, GridRowParams } from "@mui/x-data-grid"
import { useFormik } from "formik"
import { enqueueSnackbar } from "notistack"
import { useEffect } from "react"
import { useParams } from "react-router-dom"
import { Button, FileUploader, Grid, Input, Layout, Loader, LoaderContainer, Modal, Paper, Switch, Table } from "../../../components"
import { useAPI, useToggle } from "../../../hooks"
import DownloadIcon from '@mui/icons-material/Download';
import { CreateMediaSchema, UpdateMediaLibrarySchema, UpdatePromotionSchema } from "@bussola-viagens/validations"

export const PromotionPage = () => {
    const { id } = useParams()
    const [modal, modalControls] = useToggle(false)
    const [promotion, refetchPromotion] = useAPI(`/promotion/${id}`)

    const [deletedMedia, deleteMedia] = useAPI({
        method: 'DELETE'
    }, {
        manual: true
    })

    const [dowloadedFile, downloadFile] = useAPI({
        method: 'GET',
    }, {
        manual: true
    })

    const [updated, update] = useAPI({}, {
        manual: true
    })
    const [createdMedia, createMedia] = useAPI({
        url: `/promotion/${id}/promotion-media`,
        method: 'POST'
    }, {
        manual: true
    })

    const form = useFormik({
        initialValues: {
            name: '',
            description: '',
            active: false
        },
        validationSchema: UpdatePromotionSchema,
        onSubmit: async (data) => {
            const { status } = await update({
                url: `/promotion/${id}`,
                method: 'PUT',
                data
            })

            if (status <= 299) {
                enqueueSnackbar('Promoção atualizada com sucesso!', {variant: 'success'})
                refetchPromotion()
            }
        },
        initialStatus: 'draft'
    })

    const mediaForm = useFormik({
        initialValues: {
            name: '',
            file: null
        },
        validationSchema: CreateMediaSchema,
        onSubmit: async (value, helpers) => {
            const formData = new FormData();
            
            formData.append('name', value.name)
            formData.append('file', (value as unknown as { file: File }).file)
            
            const { status } = await createMedia({
                method: 'POST',
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })

            if (status <= 299) {
                enqueueSnackbar('Mídia adicionada com sucesso!', { variant: 'success' })
                refetchPromotion()
                helpers.resetForm()
                modalControls.setFalse()
            }
        }
    })

    useEffect(() => {
        if (promotion.data && form.status === 'draft') {
            form.resetForm({
                values: {
                    name: promotion.data.name,
                    description: promotion.data.description,
                    active: promotion.data.active
                },
                status: 'initiated'
            })
        }
    }, [form, promotion.data])

    if (promotion.loading || form.status === 'draft' || deletedMedia.loading || dowloadedFile.loading || updated.loading || createdMedia.loading) {
        return (
            <LoaderContainer>
                <Loader />
            </LoaderContainer>
        )
    }

    return (
        <Layout>
            <Grid container p={2} spacing={1}>
                <Grid item xs={6}>
                    <Paper>
                        <form onSubmit={form.handleSubmit}>
                            <Grid container spacing={1} p={2}>
                                <Grid item xs={12}>
                                    <Input
                                        name="name"
                                        label="Nome"
                                        value={form.values.name}
                                        onChange={form.handleChange}
                                        error={!!form.touched.name && !!form.errors.name}
                                        helperText={form.touched.name && form.errors.name}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Input 
                                        name="description"
                                        label="Descrição"
                                        multiline
                                        maxRows={4}
                                        rows={4}
                                        value={form.values.description}
                                        onChange={form.handleChange}
                                        error={!!form.touched.description && !!form.errors.description}
                                        helperText={form.touched.description && form.errors.description}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Switch 
                                        name="active"
                                        label="Ativo"
                                        onChange={() => {
                                            form.setFieldValue('active', !form.values.active, true)
                                        }}
                                        checked={form.values.active}
                                    />
                                </Grid>
                                <Grid item xs={12} textAlign="center">
                                    <Button type="submit">
                                        Salvar
                                    </Button>
                                </Grid>
                            </Grid> 
                        </form>
                    </Paper>
                </Grid>
                <Grid item xs={6}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} textAlign="right">
                            <Button onClick={modalControls.setTrue}>
                                Adicionar
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Paper>
                                <Table 
                                    columns={[
                                        { field: 'created_at', flex: 1, type: 'dateTime', headerName: 'Data de Criação', valueGetter: (value) => new Date(value), valueFormatter: formatDateTime },
                                        { field: 'name', flex: 1, type: 'string', headerName: 'Nome' },
                                        { field: 'actions', type: 'actions', getActions: (params: GridRowParams) => [
                                            <GridActionsCellItem 
                                                icon={<DownloadIcon />} 
                                                onClick={() => {
                                                    downloadFile({
                                                        url: `/file/${params.row.file_id}`,
                                                        responseType: 'blob'
                                                    }).then((res) => {
                                                        if (res.status === 200) {
                                                            const blobUrl = URL.createObjectURL(res.data);
            
                                                            window.open(blobUrl, '_blank')
                                                        }
                                                    })
                                                }} 
                                                label="Fazer Download" 
                                            />,
                                            <GridActionsCellItem 
                                                icon={<GridDeleteIcon />} 
                                                onClick={async () => {
                                                    const { status } = await deleteMedia({
                                                        url: `/promotion/${id}/promotion-media/${params.id}`,
                                                        method: 'DELETE'
                                                    })
            
                                                    if (status <= 299) {
                                                        enqueueSnackbar('Mídia excluída com sucesso!', {variant: 'success'})
                                                        refetchPromotion()
                                                    }
                                                }} 
                                                label="Fazer Download" 
                                            />,
                                        ] }
                                    ]}
                                    rows={promotion.data.promotion_medias}
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {modal && (<Modal
                open={modal}
                onClose={modalControls.setFalse}
                title="Nova Mídia"
                onConfirm={mediaForm.handleSubmit}
                confirmLabel="Adicionar"
                bodyCustomCss={{
                    width: 500
                }}
            >
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Input
                            name="name"
                            label="Nome"
                            value={mediaForm.values.name}
                            onChange={mediaForm.handleChange}
                            error={!!mediaForm.touched.name && !!mediaForm.errors.name}
                            helperText={mediaForm.touched.name && mediaForm.errors.name}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FileUploader
                            label="Mídia"
                            onChange={(value) => {
                                mediaForm.setFieldValue('file', value, true)
                            }}
                            value={mediaForm.values.file}
                            inputProps={{
                                accept: 'video/*, image/*'
                            }}
                            helperText={mediaForm.touched.file && mediaForm.errors.file}
                            error={!!mediaForm.touched.file && !!mediaForm.errors.file}
                        />
                    </Grid>
                </Grid>
            </Modal>)}
        </Layout>
    )
}