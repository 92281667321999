import { ROLES } from "@bussola-viagens/constants"
import { USER_ROLE } from "@bussola-viagens/database"
import { CreateUserSchema } from "@bussola-viagens/validations"
import { useFormik } from "formik"
import { enqueueSnackbar } from "notistack"
import { useContext } from "react"
import { Button, Grid, Input, Layout, Loader, LoaderContainer, Paper, Profile, Switch } from "../../../components"
import { AuthContext } from "../../../contexts/AuthContext"
import { useAPI } from "../../../hooks"

export const NewUserPage = () => {
    const [createdUser, createUser] = useAPI({
        url: '/user',
        method: 'POST'
    }, {
        manual: true
    })

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            role: '',
            password: '',
            password_confirmation: '',
        },
        validationSchema: CreateUserSchema,
        onSubmit: async (values, helpers) => {
            const { status } = await createUser({
                data: values
            })

            if (status <= 299) {
                enqueueSnackbar('Perfil criado com sucesso!', { variant: 'success' })
                helpers.resetForm()
            }
        },
    })

    if (createdUser.loading) {
        return (
            <LoaderContainer>
                <Loader />
            </LoaderContainer>
        )
    }

    return (
        <Layout>
            <Grid container justifyContent={'center'} padding={4}>
                <Grid item xs={10} sm={8} md={6} lg={4}>
                    <Paper>
                        <form onSubmit={formik.handleSubmit}>
                            <Grid container gap={1} p={2}>
                                <Grid item xs={12}>
                                    <Input 
                                        name="email"
                                        label="E-mail"
                                        value={formik.values.email}
                                        onChange={formik.handleChange}
                                        error={!!formik.touched.email && !!formik.errors.email}
                                        helperText={formik.touched.email && formik.errors.email}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Input 
                                        name="name"
                                        label="Nome"
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                        error={!!formik.touched.name && !!formik.errors.name}
                                        helperText={formik.touched.name && formik.errors.name}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Input 
                                        name="role"
                                        label="Cargo"
                                        select
                                        options={ROLES}
                                        value={formik.values.role}
                                        onChange={formik.handleChange}
                                        error={!!formik.touched.role && !!formik.errors.role}
                                        helperText={formik.touched.role && formik.errors.role}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Input
                                        type="password"
                                        name='password'
                                        label="Senha"
                                        value={formik.values.password}
                                        onChange={formik.handleChange}
                                        error={!!formik.touched.password && !!formik.errors.password}
                                        helperText={formik.touched.password && formik.errors.password}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Input 
                                        type="password"
                                        name="password_confirmation"
                                        label="Confirmação da Senha"
                                        value={formik.values.password_confirmation}
                                        onChange={formik.handleChange}
                                        error={!!formik.touched.password_confirmation && !!formik.errors.password_confirmation}
                                        helperText={formik.touched.password_confirmation && formik.errors.password_confirmation}
                                    />
                                </Grid>
                                <Grid item xs={12} textAlign="center" pt={2}>
                                    <Button type='submit'>
                                        Salvar
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Paper>
                </Grid>
            </Grid>
        </Layout>
    )
}

