import { CreateGroupSchema } from "@bussola-viagens/validations"
import { InputLabel } from "@mui/material"
import { useFormik } from "formik"
import { enqueueSnackbar } from "notistack"
import { Button, Grid, Input, Layout, Loader, LoaderContainer, Paper, Table, Typography } from "../../../components"
import { useAPI } from "../../../hooks"

export const NewGroupPage = () => {
    const [createdGroup, createGroup] = useAPI({
        url: '/group',
        method: 'POST'
    }, {
        manual: true
    })

    const [users] = useAPI('/user')

    const formik = useFormik({
        initialValues: {
            name: '',
            users: []
        },
        validationSchema: CreateGroupSchema,
        onSubmit: async (values, helpers) => {
            const { status } = await createGroup({
                data: values
            })

            if (status <= 299) {
                enqueueSnackbar('Grupo criado com sucesso!', { variant: 'success' })
                helpers.resetForm()
            }
        },
    })

    if (createdGroup.loading || users.loading) {
        return (
            <LoaderContainer>
                <Loader />
            </LoaderContainer>
        )
    }
    
    return (
        <Layout>
            <Grid container justifyContent={'center'} padding={4}>
                <Grid item xs={10} sm={8} md={6} lg={6}>
                    <Paper>
                        <form onSubmit={formik.handleSubmit}>
                            <Grid container gap={1} p={2}>
                                <Grid item xs={12}>
                                    <Input 
                                        name="name"
                                        label="Nome"
                                        value={formik.values.name}
                                        onChange={formik.handleChange}
                                        error={!!formik.touched.name && !!formik.errors.name}
                                        helperText={formik.touched.name && formik.errors.name}
                                    />
                                </Grid>
                                <Grid item xs={12} pt={2}>
                                    <Table
                                        rowSelection={true}
                                        checkboxSelection
                                        onRowSelectionModelChange={(value) => {
                                            formik.setFieldValue('users', value)
                                        }}               
                                        rowSelectionModel={formik.values.users}   
                                        columns={[
                                            {
                                                field: 'name',
                                                type: 'string',
                                                headerName: 'Nome',
                                                flex: 1
                                            },
                                            {
                                                field: 'email',
                                                type: 'string',
                                                headerName: 'Email',
                                                flex: 1
                                            },
                                            {
                                                field: 'group',
                                                type: 'string',
                                                headerName: 'Grupo',
                                                flex: 1,
                                                valueGetter: (value: { name: string }) => {
                                                    return value?.name ? value.name : ''
                                                }
                                            }
                                        ]}
                                        rows={users.data || []}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography fontStyle="italic" fontSize={14}>
                                        Se você selecionar um usuário que já está em um grupo, ele será removido e adicionado neste novo grupo.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} textAlign="center" pt={2}>
                                    <Button type='submit'>
                                        Salvar
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Paper>
                </Grid>
            </Grid>
        </Layout>
    )
}

