import { GridActionsCellItem, GridRowParams } from "@mui/x-data-grid"
import { useNavigate } from "react-router-dom"
import { Button, Grid, Input, Layout, Loader, LoaderContainer, Paper, Profile, Table } from "../../components"
import { useAPI } from "../../hooks"
import VisibilityIcon from '@mui/icons-material/Visibility';
import {formatDateTime} from '@bussola-viagens/utils'

export const GroupsPage = () => {
    const navigate = useNavigate()

    const [groups] = useAPI('/group')

    if (groups.loading) {
        return (
            <LoaderContainer>
                <Loader />
            </LoaderContainer>
        )
    }

    return (
        <Layout>
            <Grid container justifyContent={'center'} padding={4} gap={1}>
                <Grid item xs={10} textAlign="right">
                    <Button href="/grupos/novo">
                        Adicionar
                    </Button>
                </Grid>
                <Grid item xs={10} >
                    <Paper>
                        <Table 
                            columns={[
                                {
                                    field: 'created_at',
                                    type: 'dateTime',
                                    headerName: 'Data de Criação',
                                    flex: 1,
                                    valueGetter: (value) => new Date(value),
                                    valueFormatter: (value) => {
                                        return formatDateTime(value)
                                    }
                                },
                                {
                                    field: 'name',
                                    type: 'string',
                                    headerName: 'Nome',
                                    flex: 1
                                },
                                {
                                    field: 'users',
                                    type: 'number',
                                    headerName: 'Usuários',
                                    flex: 1,
                                    valueGetter: (value: Array<any>) => {
                                        return value?.length
                                    }
                                },
                                {
                                    field: 'actions',
                                    type: 'actions',
                                    getActions: (params: GridRowParams) => [
                                        <GridActionsCellItem icon={<VisibilityIcon />} onClick={() => {
                                            navigate(`/grupos/${params.id}`)
                                        }} label="Ver Detalhes" />,
                                    ]
                                    
                                }
                            ]}
                            rows={groups.data || []}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </Layout>
    )
}