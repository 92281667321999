import { GridActionsCellItem, GridDeleteIcon, GridRowParams } from "@mui/x-data-grid"
import { useContext } from "react"
import { Grid, Layout, Loader, LoaderContainer, Paper, Table } from "../../components"
import { AuthContext } from "../../contexts"
import DownloadIcon from '@mui/icons-material/Download';
import { useAPI } from "../../hooks";
import { formatDateTime } from "@bussola-viagens/utils";
import {Navigate} from 'react-router-dom'

export const DocumentsPage = () => {
    const { me } = useContext(AuthContext)

    const [dowloadedFile, downloadFile] = useAPI({
        method: 'GET',
    }, {
        manual: true
    })

    if (!me?.group.group_module?.is_documents_enabled) {
        return (
            <Navigate to="/home" />
        )
    }

    if (dowloadedFile.loading) {
        return (
            <LoaderContainer>
                <Loader />
            </LoaderContainer>
        )
    }

    return (
        <Layout>
            <Grid container p={1} justifyContent="center">
                <Grid item xs={10}>
                    <Paper>
                        <Table 
                            columns={[
                                { field: 'created_at', flex: 1, type: 'dateTime', headerName: 'Data de Criação', valueGetter: (value) => new Date(value), valueFormatter: formatDateTime },
                                { field: 'creator', flex: 1, type: 'string', headerName: 'Autor', valueGetter: (_, row) => row.file.creator.name},
                                { field: 'name', flex: 1, type: 'string', headerName: 'Nome' },
                                { field: 'actions', type: 'actions', getActions: (params: GridRowParams) => [
                                    <GridActionsCellItem 
                                        icon={<DownloadIcon />} 
                                        onClick={() => {
                                            downloadFile({
                                                url: `/file/${params.row.file_id}`,
                                                responseType: 'blob'
                                            }).then((res) => {
                                                if (res.status === 200) {
                                                    const blobUrl = URL.createObjectURL(res.data);

                                                    window.open(blobUrl, '_blank')
                                                }
                                            })
                                        }} 
                                        label="Fazer Download" 
                                    />,
                                ] }
                            ]}
                            rows={me?.group?.group_document || []}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </Layout>
    )
}