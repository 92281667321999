import { PROGRAM } from "@bussola-viagens/constants"
import { UpdateGroupCompanyConfigurationSchema } from "@bussola-viagens/validations"
import { useFormik } from "formik"
import { enqueueSnackbar } from "notistack"
import { useCallback, useEffect, useMemo, useState } from "react"
import { Grid, Paper, LoaderContainer, Loader } from "../../../../components"
import { useAPI } from "../../../../hooks"
import { CompanyARSettingForm } from "./CompanyARSettingForm"
import { CompanySettingForm } from "./CompanySettingForm"
import { PriceSetting } from "./PriceSettingsInput"

const defaultValues = {
    prices: [] as PriceSetting[],
    flight_in_next_seven_days: '',
    comments: ''
}

interface ProgramsProps {
    groupId: string
}

export const Programs = ({groupId}: ProgramsProps) => {
    const [setup, setSetup] = useState(false)
    const [group, refetchGroup] = useAPI(`/group/${groupId}`)

    const [updatedGroupConfiguration, updateGroupConfiguration] = useAPI({
        url: `/group/${groupId}/configuration`,
        method: 'PUT'
    }, {
        manual: true
    })

    const [deletedGroupConfiguration, deleteGroupConfiguration] = useAPI({
        url: `/group/${groupId}/configuration`,
        method: 'DELETE'
    }, {
        manual: true
    })

    const updateGroupConf = useCallback(async (values: { [key: string]: any }, company: PROGRAM) => {
        const { status } = await updateGroupConfiguration({
            url: `/group/${groupId}/configuration/${company}`,
            data: values
        })

        if (status <= 299) {
            enqueueSnackbar('Configuração atualizada com sucesso!', { variant: 'success' })
        }
    }, [groupId, updateGroupConfiguration])

    const iberiaFormik = useFormik({
        initialValues: defaultValues,
        validationSchema: UpdateGroupCompanyConfigurationSchema,
        onSubmit: async (values) => {
            updateGroupConf(values, PROGRAM.IBERIA_PLUS)
        }
    })

    const smilesBRFormik = useFormik({
        initialValues: defaultValues,
        validationSchema: UpdateGroupCompanyConfigurationSchema,
        onSubmit: async (values) => {
            updateGroupConf(values, PROGRAM.SMILES_BR)
        }
    })

    const tapFormik = useFormik({
        initialValues: defaultValues,
        validationSchema: UpdateGroupCompanyConfigurationSchema,
        onSubmit: async (values) => {
            updateGroupConf(values, PROGRAM.TAP)
        }
    })

    const smilesARFormik = useFormik({
        initialValues: {
            ...defaultValues,
            blue_exchange_brl: ''
        },
        validationSchema: UpdateGroupCompanyConfigurationSchema,
        onSubmit: async (values) => {
            updateGroupConf(values, PROGRAM.SMILES_AR)
        }
    })

    const azulInterlineFormik = useFormik({
        initialValues: defaultValues,
        validationSchema: UpdateGroupCompanyConfigurationSchema,
        onSubmit: async (values) => {
            updateGroupConf(values, PROGRAM.AZUL_INTERLINE)
        }
    })

    const azulViagensFormik = useFormik({
        initialValues: defaultValues,
        validationSchema: UpdateGroupCompanyConfigurationSchema,
        onSubmit: async (values) => {
            updateGroupConf(values, PROGRAM.AZUL_VIAGENS)
        }
    })

    const tudoAzulFormik = useFormik({
        initialValues: defaultValues,
        validationSchema: UpdateGroupCompanyConfigurationSchema,
        onSubmit: async (values) => {
            updateGroupConf(values, PROGRAM.TUDO_AZUL)
        }
    })

    const aadvantageFormik = useFormik({
        initialValues: defaultValues,
        validationSchema: UpdateGroupCompanyConfigurationSchema,
        onSubmit: async (values) => {
            updateGroupConf(values, PROGRAM.AADVANTAGE)
        }
    })

    const latamARFormik = useFormik({
        initialValues: {
            ...defaultValues,
            blue_exchange_brl: ''
        },
        validationSchema: UpdateGroupCompanyConfigurationSchema,
        onSubmit: async (values) => {
            updateGroupConf(values, PROGRAM.LATAMPASS_AR)
        }
    })

    const latamBRFormik = useFormik({
        initialValues: defaultValues,
        validationSchema: UpdateGroupCompanyConfigurationSchema,
        onSubmit: async (values) => {
            updateGroupConf(values, PROGRAM.LATAMPASS_BR)
        }
    })

    const airEuropaFormik = useFormik({
        initialValues: defaultValues,
        validationSchema: UpdateGroupCompanyConfigurationSchema,
        onSubmit: async (values) => {
            updateGroupConf(values, PROGRAM.AIR_EUROPA)
        }
    })

    const lifeMilesFormik = useFormik({
        initialValues: defaultValues,
        validationSchema: UpdateGroupCompanyConfigurationSchema,
        onSubmit: async (values) => {
            updateGroupConf(values, PROGRAM.LIFEMILES)
        }
    })

    const flyingBlueFormik = useFormik({
        initialValues: defaultValues,
        validationSchema: UpdateGroupCompanyConfigurationSchema,
        onSubmit: async (values) => {
            updateGroupConf(values, PROGRAM.FLYING_BLUE)
        }
    })

    const connectMileFormik = useFormik({
        initialValues: defaultValues,
        validationSchema: UpdateGroupCompanyConfigurationSchema,
        onSubmit: async (values) => {
            updateGroupConf(values, PROGRAM.CONNECT_MILES)
        }
    })

    const aeroplanFormik = useFormik({
        initialValues: defaultValues,
        validationSchema: UpdateGroupCompanyConfigurationSchema,
        onSubmit: async (values) => {
            updateGroupConf(values, PROGRAM.AEROPLAN)
        }
    })

    const tudoAzulV2Formik = useFormik({
        initialValues: defaultValues,
        validationSchema: UpdateGroupCompanyConfigurationSchema,
        onSubmit: async (values) => {
            updateGroupConf(values, PROGRAM.TUDO_AZUL_V2)
        }
    })

    const forms = useMemo(() => [
        { program: PROGRAM.SMILES_BR, label: 'Smiles BR', formik: smilesBRFormik },
        { program: PROGRAM.SMILES_AR, label: 'Smiles AR', formik: smilesARFormik, CustomForm: CompanyARSettingForm },
        { program: PROGRAM.LATAMPASS_BR, label: 'Latam BR', formik: latamBRFormik },
        { program: PROGRAM.LATAMPASS_AR, label: 'Latam AR', formik: latamARFormik, CustomForm: CompanyARSettingForm },
        { program: PROGRAM.AADVANTAGE, label: 'AAdvantage', formik: aadvantageFormik },
        { program: PROGRAM.TUDO_AZUL, label: 'Tudo Azul', formik: tudoAzulFormik },
        { program: PROGRAM.TUDO_AZUL_V2, label: 'Tudo Azul (Menos Milhas)', formik: tudoAzulV2Formik },
        { program: PROGRAM.AZUL_INTERLINE, label: 'Azul Interline', formik: azulInterlineFormik },
        { program: PROGRAM.AZUL_VIAGENS, label: 'Azul Viagens', formik: azulViagensFormik },
        { program: PROGRAM.IBERIA_PLUS, label: 'Iberia Plus', formik: iberiaFormik },
        { program: PROGRAM.TAP, label: 'TAP', formik: tapFormik },
        { program: PROGRAM.AIR_EUROPA, label: 'Air Europa SUMA', formik: airEuropaFormik },
        { program: PROGRAM.LIFEMILES, label: 'Lifemiles', formik: lifeMilesFormik },
        { program: PROGRAM.FLYING_BLUE, label: 'Flying Blue', formik: flyingBlueFormik },
        { program: PROGRAM.CONNECT_MILES, label: 'Connect Miles', formik: connectMileFormik },
        { program: PROGRAM.AEROPLAN, label: 'Aeroplan', formik: aeroplanFormik },
    ], [aadvantageFormik, aeroplanFormik, airEuropaFormik, azulInterlineFormik, azulViagensFormik, connectMileFormik, flyingBlueFormik, iberiaFormik, latamARFormik, latamBRFormik, lifeMilesFormik, smilesARFormik, smilesBRFormik, tapFormik, tudoAzulFormik, tudoAzulV2Formik])
    
    const onClearClick = useCallback(async (company: PROGRAM) => {
        const { status } = await deleteGroupConfiguration({
            url: `/group/${groupId}/configuration/${company}`
        })

        if (status <= 299) {
            enqueueSnackbar('Configuração atualizada com sucesso!', { variant: 'success' })
            window.location.reload()
        }
    }, [deleteGroupConfiguration, groupId])

    useEffect(() => {
        if (group.data && !setup) { 
            setSetup(true)   
            forms.forEach(form => {
                form.formik.resetForm({
                    values: {
                        ...form.formik.values,
                        ...(group?.data?.configuration?.[form.program] || {})
                    },
                })
            })
        }
    }, [group.data, forms, setup])

    if (group.loading || !setup) {
        return (
            <LoaderContainer>
                <Loader />
            </LoaderContainer>
        )
    }

    return (
        <Grid container spacing={1}>
            {
                forms.map((form, index) => {
                    return (
                        <Grid item xs={12} key={index.toString()}>
                            <Paper>
                                {
                                    
                                    [PROGRAM.LATAMPASS_AR, PROGRAM.SMILES_AR].includes(form.program) ? (
                                        <CompanyARSettingForm label={form.label} formik={form.formik as typeof smilesARFormik} program={form.program} onClear={() => {
                                            onClearClick(form.program)
                                        }}/>
                                    ) : (
                                        <CompanySettingForm label={form.label} formik={form.formik as typeof smilesBRFormik} program={form.program} onClear={() => {
                                            onClearClick(form.program)
                                        }}/>
                                    )
                                }
                            </Paper>
                        </Grid>
                    )
                })
            }
        </Grid>
    )
}