import { CssBaseline } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { ThemeProvider } from './components';
import { AuthContextProvider } from './contexts/AuthContext';
import RouterProvider from './pages'

function App() {
  return (
    <ThemeProvider>
      <SnackbarProvider>
      <AuthContextProvider>
        <CssBaseline />
        <RouterProvider />
      </AuthContextProvider>
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
