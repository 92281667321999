import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material';
import { PropsWithChildren } from 'react';

const theme = createTheme({
  palette: {
    background: {
      default: '#b6b6b6'
    },
    primary: {
      main: '#000096'
    }
  }
})

export const ThemeProvider = ({children}: PropsWithChildren) => {
    return (
      <MuiThemeProvider theme={theme}>
        {children}
      </MuiThemeProvider>
    )
}