import { GridActionsCellItem, GridRowParams } from "@mui/x-data-grid"
import { Button, Grid, Input, Layout, Loader, LoaderContainer, Modal, Paper, Table } from "../../components"
import { useAPI, useToggle } from "../../hooks";
import { enqueueSnackbar } from "notistack";
import { formatDateTime } from "@bussola-viagens/utils";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { CreateMediaLibrarySchema, CreatePromotionSchema } from "@bussola-viagens/validations";

export const PromotionsPage = () => {
    const navigate = useNavigate()
    const [modal, modalControls] = useToggle(false)
    const [promotions, refetchPromotions] = useAPI(`/promotion`)
    const [created, create] = useAPI({}, {manual: true})

    const form = useFormik({
        initialValues: {
            name: '',
            description: '',
        },
        validationSchema: CreatePromotionSchema,
        onSubmit: async (data, helpers) => {
            const { status } = await create({
                url: `/promotion`,
                data,
                method: 'POST'
            })

            if (status <= 299) {
                enqueueSnackbar('Promoção criada com sucesso!', { variant: 'success' })
                modalControls.setFalse()
                helpers.resetForm()
                refetchPromotions()
            }
        }
    })

    if (created.loading || promotions.loading) {
        return (
            <LoaderContainer>
                <Loader />
            </LoaderContainer>
        )
    }

    return (
        <Layout>
            <Grid container p={1} justifyContent="center" spacing={1}>
                <Grid item xs={10} textAlign="right">
                    <Button
                        onClick={modalControls.setTrue}
                    >
                        Adicionar
                    </Button>
                </Grid>
                <Grid item xs={10}>
                    <Paper>
                        <Table 
                            columns={[
                                { field: 'created_at', flex: 1, type: 'dateTime', headerName: 'Data de Criação', valueGetter: (value) => new Date(value), valueFormatter: formatDateTime },
                                { field: 'name', flex: 1, type: 'string', headerName: 'Nome' },
                                { field: 'description', flex: 1, type: 'string', headerName: 'Descrição' },
                                { field: 'active', flex: 1, type: 'boolean', headerName: 'Ativo' },
                                { field: 'actions', type: 'actions', getActions: (params: GridRowParams) => [
                                    <GridActionsCellItem 
                                        icon={<VisibilityIcon />} 
                                        onClick={() => {
                                            navigate(`/promocoes/${params.id}`)
                                        }} 
                                        label="Ver Detalhes" 
                                    />,
                                ] }
                            ]}
                            rows={promotions.data || []}
                        />
                    </Paper>
                </Grid>
            </Grid>
            {modal && (<Modal
                title="Nova Promoção"
                open={modal}
                onConfirm={form.handleSubmit}
                confirmLabel="Adicionar"
                bodyCustomCss={{
                    width: 400
                }}
                onClose={modalControls.setFalse}
            >
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <Input 
                            name="name"
                            label="Nome"
                            value={form.values.name}
                            onChange={form.handleChange}
                            error={!!form.touched.name && !!form.errors.name}
                            helperText={form.touched.name && form.errors.name}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Input 
                            name="description"
                            label="Descrição"
                            multiline
                            maxRows={4}
                            rows={4}
                            value={form.values.description}
                            onChange={form.handleChange}
                            error={!!form.touched.description && !!form.errors.description}
                            helperText={form.touched.description && form.errors.description}
                        />
                    </Grid>
                </Grid>
            </Modal>)}
        </Layout>
    )
}