import {
    createBrowserRouter,
    RouterProvider,
    Navigate
} from "react-router-dom";
import { AuthRoute, UnAuthRoute } from "../components";
import { DocumentsPage } from "./documents";
import { GroupsPage } from "./groups";
import { NewGroupPage } from "./groups/new";
import { GroupPage } from "./groups/[id]";
import { HomePage } from "./home";
import { LoginPage } from "./login";
import { MediaLibrariesPage } from "./media-library";
import { MediaLibraryPage } from "./media-library/[id]";
import { MyMediaLibrariesPage } from "./my-media-library";
import { MyMediaLibraryPage } from "./my-media-library/[id]";
import { MyOffersPage } from "./my-offers";
import { NewOfferPage } from "./my-offers/new";
import { MyProfilePage } from "./my-profile";
import { MyPromotionsPage } from "./my-promotions";
import { MyPromotionPage } from "./my-promotions/[id]";
import { MySolicitationsPage } from "./my-solicitations";
import { MySolicitationPage } from "./my-solicitations/[id]";
import { MyTrainingsPage } from "./my-trainings";
import { MyTrainingPage } from "./my-trainings/[id]";
import { OffersPage } from "./offers";
import { PromotionsPage } from "./promotions";
import { PromotionPage } from "./promotions/[id]";
import { SolicitationsPage } from "./solicitations";
import { SolicitationPage } from "./solicitations/[id]";
import { TrainingsPage } from "./trainings";
import { TrainingPage } from "./trainings/[id]";
import { UsersPage } from "./users";
import { NewUserPage } from "./users/new";
import { UserPage } from "./users/[id]";

const router = createBrowserRouter([
    {
        path: "/",
        element: <UnAuthRoute><Navigate to="/login" /></UnAuthRoute>,
    },
    {
        path: "/login",
        element: <UnAuthRoute><LoginPage /></UnAuthRoute>,
    },
    {
        path: "/home",
        element: <AuthRoute><HomePage /></AuthRoute>,
    },
    {
        path: "/meu-perfil",
        element: <AuthRoute><MyProfilePage /></AuthRoute>,
    },
    {
        path: "/usuarios",
        element: <AuthRoute><UsersPage /></AuthRoute>,
    },
    {
        path: "/usuarios/:id",
        element: <AuthRoute><UserPage /></AuthRoute>,
    },
    {
        path: "/usuarios/novo",
        element: <AuthRoute><NewUserPage /></AuthRoute>,
    },
    {
        path: "/grupos",
        element: <AuthRoute><GroupsPage /></AuthRoute>,
    },
    {
        path: "/grupos/:id",
        element: <AuthRoute><GroupPage /></AuthRoute>,
    },
    {
        path: "/grupos/novo",
        element: <AuthRoute><NewGroupPage /></AuthRoute>,
    },
    {
        path: "/documentos",
        element: <AuthRoute><DocumentsPage /></AuthRoute>,
    },
    {
        path: "/treinamentos",
        element: <AuthRoute><TrainingsPage /></AuthRoute>,
    },
    {
        path: "/treinamentos/:id",
        element: <AuthRoute><TrainingPage /></AuthRoute>,
    },
    {
        path: "/meus-treinamentos",
        element: <AuthRoute><MyTrainingsPage /></AuthRoute>,
    },
    {
        path: "/meus-treinamentos/:id",
        element: <AuthRoute><MyTrainingPage /></AuthRoute>,
    },
    {
        path: "/banco-de-midias",
        element: <AuthRoute><MediaLibrariesPage /></AuthRoute>,
    },
    {
        path: "/banco-de-midias/:id",
        element: <AuthRoute><MediaLibraryPage /></AuthRoute>,
    },
    {
        path: "/meus-bancos-de-midias",
        element: <AuthRoute><MyMediaLibrariesPage /></AuthRoute>,
    },
    {
        path: "/meus-bancos-de-midias/:id",
        element: <AuthRoute><MyMediaLibraryPage /></AuthRoute>,
    },
    {
        path: "/promocoes",
        element: <AuthRoute><PromotionsPage /></AuthRoute>,
    },
    {
        path: "/promocoes/:id",
        element: <AuthRoute><PromotionPage /></AuthRoute>,
    },
    {
        path: "/minhas-promocoes",
        element: <AuthRoute><MyPromotionsPage /></AuthRoute>,
    },
    {
        path: "/minhas-promocoes/:id",
        element: <AuthRoute><MyPromotionPage /></AuthRoute>,
    },
    {
        path: "/pedidos",
        element: <AuthRoute><SolicitationsPage /></AuthRoute>,
    },
    {
        path: "/pedidos/:id",
        element: <AuthRoute><SolicitationPage /></AuthRoute>,
    },
    {
        path: "/meus-pedidos",
        element: <AuthRoute><MySolicitationsPage /></AuthRoute>,
    },
    {
        path: "/meus-pedidos/:id",
        element: <AuthRoute><MySolicitationPage /></AuthRoute>,
    },
    {
        path: "/meus-orcamentos/novo",
        element: <AuthRoute><NewOfferPage /></AuthRoute>,
    },
    {
        path: "/meus-orcamentos",
        element: <AuthRoute><MyOffersPage /></AuthRoute>,
    },
    {
        path: "/orcamentos",
        element: <AuthRoute><OffersPage /></AuthRoute>,
    }
]);

const Provider = () => {
    return (
        <RouterProvider router={router} />
    )
}

export default Provider