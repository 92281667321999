import { Fragment } from "react"
import { Button, CurrencyInput, Grid, Input, Modal, NumericInput } from "../../../../components"
import AddIcon from '@mui/icons-material/Add';
import { useFormik } from "formik";
import * as Yup from 'yup'
import { get } from "lodash";
import DeleteIcon from '@mui/icons-material/Remove';
import { enqueueSnackbar } from 'notistack';
import { useToggle } from "../../../../hooks";
import { formatMoney } from "@bussola-viagens/utils";
// import { UpdateProgramPriceSettingsValidationSchema } from "@next-pontos/validations";

export interface PriceSetting {
    from: number,
    to: number,
    price: number
}

interface PriceSettingsInputProps {
    value: PriceSetting[],
    onChange: (value: PriceSetting[]) => void
}

export const PriceSettingsInput = (props: PriceSettingsInputProps) => {
    const [modalVisibility, modalVisibilityControls] = useToggle(false)

    const form = useFormik({
        initialValues: {
            prices: props.value.map(item => ({
                ...item,
                price: Number(item.price)
            }))
        },
        validationSchema: Yup.object({
            // prices: UpdateProgramPriceSettingsValidationSchema
        }),
        onSubmit: (values, helpers) => {
            const lastItem = values.prices[values.prices.length - 1]
            
            if (lastItem.to) {
                helpers.setFieldError(`prices[${values.prices.length - 1}].to`, 'Este campo deve ser vazio.')
                enqueueSnackbar('O campo "Até" do último intervalo deve ser vazio, para que aceite qualquer valor acima do inicial.', {
                    variant: 'error'
                })
            } else {
                props.onChange(values.prices)
                modalVisibilityControls.setFalse()
            }
        }
    })

    const inputValue = !props.value.length ? '' : props.value.length === 1 ? formatMoney(props.value[0].price) : `${formatMoney(props.value[0].price)} - ${formatMoney(props.value[props.value.length - 1].price)}`

    return (
        <Fragment>
            <Input 
                label="CPM (R$)"
                value={inputValue}
                onClick={modalVisibilityControls.setTrue}
            />
            <Modal
                open={modalVisibility}
                title="Configuração de Preços"
                onConfirm={form.handleSubmit}
                confirmLabel="Salvar"
                bodyCustomCss={{
                    width: 500
                }}
                onClose={modalVisibilityControls.setFalse}
            >
                <Grid container spacing={2}>
                    {
                        form.values.prices.map((item, index) => {
                            const fieldBaseName = `prices[${index}]`

                            return (
                                <Grid item xs={12}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={3}>
                                            <NumericInput 
                                                label="De"
                                                name={`${fieldBaseName}.from`}
                                                onChange={form.handleChange}
                                                onBlur={form.handleBlur}
                                                value={get(form.values, `${fieldBaseName}.from`)}
                                                helperText={get(form.touched, `${fieldBaseName}.from`) && get(form.errors, `${fieldBaseName}.from`)}
                                                error={!!get(form.touched, `${fieldBaseName}.from`) && !!get(form.errors, `${fieldBaseName}.from`)}
                                            />
                                        </Grid>
                                        <Grid item xs={3}>
                                            <NumericInput 
                                                label="Até"
                                                name={`${fieldBaseName}.to`}
                                                onChange={form.handleChange}
                                                onBlur={form.handleBlur}
                                                value={get(form.values, `${fieldBaseName}.to`)}
                                                helperText={get(form.touched, `${fieldBaseName}.to`) && get(form.errors, `${fieldBaseName}.to`)}
                                                error={!!get(form.touched, `${fieldBaseName}.to`) && !!get(form.errors, `${fieldBaseName}.to`)}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <CurrencyInput
                                                label="Valor"
                                                onChangeValue={(_, originalValue) => {
                                                    const fieldName = `${fieldBaseName}.price`

                                                    form.setFieldValue(fieldName, originalValue)
                                                }}
                                                value={get(form.values, `${fieldBaseName}.price`)}
                                                onBlur={form.handleBlur}
                                                helperText={get(form.touched, `${fieldBaseName}.price`) && get(form.errors, `${fieldBaseName}.price`)}
                                                error={!!get(form.touched, `${fieldBaseName}.price`) && !!get(form.errors, `${fieldBaseName}.price`)}
                                            />
                                        </Grid>
                                        <Grid item xs={2} alignItems="center" justifyContent={"center"} display="flex">
                                            <Button variant="text"
                                                onClick={() => {
                                                    form.setFieldValue('prices', form.values.prices.filter((_, i) => i !== index))
                                                }}
                                            >
                                                <DeleteIcon />
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            )
                        })
                    }
                    <Grid item xs={12} textAlign="right">
                        <Button variant="text" onClick={() => {
                            const values = form.values.prices
                            
                            // values.sort((a, b) => a.from - b.from)

                            const last = values[values.length - 1]

                            if (last && !last.to) {
                                return enqueueSnackbar('Configure corretamente o último intervalo antes de adicionar o próximo.', {
                                    variant: 'error'
                                })
                            }

                            const nextFrom = Number(last?.to || 0) + 1

                            const next = {
                                from: nextFrom,
                                to: '',
                                price: ''
                            }

                            form.setFieldValue('prices', [
                                ...values,
                                next
                            ])
                        }}>
                            <AddIcon />
                        </Button>
                    </Grid>
                </Grid>
            </Modal>
        </Fragment>
    )
}