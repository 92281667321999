import { PROGRAM } from "@bussola-viagens/constants"
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary } from "@mui/material"
import { GridExpandMoreIcon } from "@mui/x-data-grid"
import { FormikProps } from "formik"
import { Button, CurrencyInput, Input, Typography } from "../../../../components"
import { PriceSetting, PriceSettingsInput } from "./PriceSettingsInput"

interface CompanyARSettingFormProps {
    label: string
    program: PROGRAM,
    onClear: () => void
    formik: FormikProps<{
        prices: PriceSetting[],
        flight_in_next_seven_days: string,
        blue_exchange_brl: string,
        comments: string
    }>
}

export const CompanyARSettingForm = ({label, formik, onClear}: CompanyARSettingFormProps) => {
    return (
        <form onSubmit={formik.handleSubmit}>
            <Accordion>
                <AccordionSummary
                    expandIcon={<GridExpandMoreIcon />}
                    style={{
                        fontWeight: 'bold'
                    }}
                >
                    {label}
                </AccordionSummary>
                <AccordionDetails>
                    <PriceSettingsInput 
                        onChange={(value) => {
                            formik.setFieldValue('prices', value)
                        }}
                        value={formik.values.prices || []}
                    />
                    <CurrencyInput
                        style={{
                            marginTop: 10
                        }}
                        label="CPM para voos em menos de 7 dias (R$)"
                        onChangeValue={(_, originalValue) => {
                            formik.setFieldValue('flight_in_next_seven_days', originalValue, true)
                        }}
                        value={formik.values.flight_in_next_seven_days}
                    />
                    <CurrencyInput
                        style={{
                            marginTop: 10
                        }}
                        label="Cotação Blue (R$)"
                        onChangeValue={(_, originalValue) => {
                            formik.setFieldValue('blue_exchange_brl', originalValue, true)
                        }}
                        value={formik.values.blue_exchange_brl}
                    />
                    <Typography mt={0} mb={2} fontStyle="italic" fontSize={14}>
                        Valores para cada mil milhas por CPF (beneficiário) da emissão.
                    </Typography>
                    <Input
                        label="Observações"
                        multiline
                        maxRows={4}
                        rows={4}
                        name="comments"
                        value={formik.values.comments}
                        onChange={formik.handleChange}
                        error={!!formik.touched.comments && !!formik.errors.comments}
                        helperText={formik.touched.comments && formik.errors.comments}
                    />
                </AccordionDetails>
                <AccordionActions>
                    <Button color="error" onClick={onClear}>Remover</Button>
                    <Button type="submit" disabled={!formik.isValid || !formik.dirty}>Salvar</Button>
                </AccordionActions>
            </Accordion>
        </form>
    )
}