import { formatDateTime } from "@bussola-viagens/utils"
import { GridActionsCellItem, GridDeleteIcon, GridRowParams } from "@mui/x-data-grid"
import { useFormik } from "formik"
import { enqueueSnackbar } from "notistack"
import { Fragment, useContext, useEffect } from "react"
import { Navigate, useParams } from "react-router-dom"
import { Button, FileUploader, Grid, Input, Layout, Loader, LoaderContainer, Modal, Paper, Table, Typography } from "../../../components"
import { useAPI, useToggle } from "../../../hooks"
import DownloadIcon from '@mui/icons-material/Download';
import { CreateLessonSchema, UpdateTrainingSchema } from "@bussola-viagens/validations"
import { Chip, ChipOwnProps, Divider } from "@mui/material"
import { nl2br } from "@bussola-viagens/utils"
import { SOLICITATION_STATUS } from "@bussola-viagens/constants"
import { AuthContext } from "../../../contexts"

export const MySolicitationPage = () => {
    const { id } = useParams()
    const [solicitation] = useAPI(`/solicitation/${id}`)
    
    const { me } = useContext(AuthContext)

    if (!me?.group.group_module?.is_solicitation_enabled) {
        return (
            <Navigate to="/home" />
        )
    }

    if (solicitation.loading) {
        return (
            <LoaderContainer>
                <Loader />
            </LoaderContainer>
        )
    }

    return (
        <Layout>
            <Grid container p={1} spacing={1} justifyContent="center">
                <Grid item xs={6}>
                    <Paper sx={{
                        p: 2
                    }}>
                        <Typography variant="h6" textAlign="center">
                            ID: {solicitation.data.id}
                        </Typography>
                        <Divider sx={{
                            mt: 1,
                            mb: 1
                        }}/>
                        <Grid container spacing={2}>
                            <Grid item xs={12} justifyContent="space-between" flexDirection="row" display="flex">
                                <Typography fontWeight="bold">
                                    Status:
                                </Typography>
                                <Chip label={SOLICITATION_STATUS.find(s => s.value === solicitation.data.status)?.label} color={SOLICITATION_STATUS.find(s => s.value === solicitation.data.status)?.chipColor as ChipOwnProps['color']} />
                            </Grid>
                            <Grid item xs={12} justifyContent="space-between" flexDirection="row" display="flex">
                                <Typography fontWeight="bold">
                                    Data:
                                </Typography>
                                <Typography>
                                    {formatDateTime(solicitation.data.created_at)}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} justifyContent="space-between" flexDirection="row" display="flex">
                                <Typography fontWeight="bold">
                                    Autor:
                                </Typography>
                                <Typography>
                                    {solicitation.data.creator.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} justifyContent="space-between" flexDirection="row" display="flex">
                                <Typography fontWeight="bold">
                                    Grupo:
                                </Typography>
                                <Typography>
                                    {solicitation.data.group.name}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} justifyContent="space-between" flexDirection="row" display="flex">
                                <Typography fontWeight="bold">
                                    Título:
                                </Typography>
                                <Typography>
                                    {solicitation.data.title}
                                </Typography>
                            </Grid>
                            <Grid item xs={12} justifyContent="space-between" flexDirection="row" display="flex">
                                <Typography fontWeight="bold">
                                    Descrição:
                                </Typography>
                                <Typography dangerouslySetInnerHTML={{
                                    __html: nl2br(solicitation.data.description)
                                }} />
                            </Grid>
                            {solicitation.data.solicitation_reply && (
                                <Fragment>
                                    <Grid item xs={12}>
                                    <Divider sx={{
                                        mt: 1,
                                        mb: 1
                                    }}/>
                                    </Grid>
                                    <Grid item xs={12} justifyContent="space-between" flexDirection="row" display="flex">
                                        <Typography fontWeight="bold">
                                            Respondido por:
                                        </Typography>
                                        <Typography>
                                            {solicitation.data.solicitation_reply.creator.name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} justifyContent="space-between" flexDirection="row" display="flex">
                                        <Typography fontWeight="bold">
                                            Data:
                                        </Typography>
                                        <Typography>
                                            {formatDateTime(solicitation.data.solicitation_reply.created_at)}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12} justifyContent="space-between" flexDirection="row" display="flex">
                                        <Typography fontWeight="bold">
                                            Descrição:
                                        </Typography>
                                        <Typography dangerouslySetInnerHTML={{
                                            __html: nl2br(solicitation.data.solicitation_reply.description)
                                        }} />
                                    </Grid>
                                </Fragment>
                            )}
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>
        </Layout>
    )
}