import CircularProgress from '@mui/material/CircularProgress';
import { PropsWithChildren } from 'react';

export const Loader = () => {
    return <CircularProgress />
}

export const LoaderContainer: React.FC<PropsWithChildren> = ({children}) => {
    return <div style={{
        display: 'flex',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center'
    }}>{children}</div>
}