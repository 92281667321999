import { ROLES } from "@bussola-viagens/constants"
import { useTheme } from "@mui/material"
import { GridActionsCellItem, GridRowParams } from "@mui/x-data-grid"
import { useFormik } from "formik"
import { useContext } from "react"
import { useNavigate } from "react-router-dom"
import { Button, Grid, Input, Layout, Loader, LoaderContainer, Paper, Profile, Table } from "../../components"
import { AuthContext } from "../../contexts/AuthContext"
import { useAPI } from "../../hooks"
import VisibilityIcon from '@mui/icons-material/Visibility';
import { formatDateTime } from "@bussola-viagens/utils"

export const UsersPage = () => {
    const navigate = useNavigate()

    const [users] = useAPI('/user')

    if (users.loading) {
        return (
            <LoaderContainer>
                <Loader />
            </LoaderContainer>
        )
    }
    
    return (
        <Layout>
            <Grid container justifyContent={'center'} padding={4} gap={1}>
                <Grid item xs={10} textAlign="right">
                    <Button href="/usuarios/novo">
                        Adicionar
                    </Button>
                </Grid>
                <Grid item xs={10} >
                    <Paper>
                        <Table 
                            columns={[
                                {
                                    field: 'created_at',
                                    type: 'dateTime',
                                    headerName: 'Data de Criação',
                                    flex: 1,
                                    valueGetter: (value) => new Date(value),
                                    valueFormatter: (value) => {
                                        return formatDateTime(value)
                                    }
                                },
                                {
                                    field: 'name',
                                    type: 'string',
                                    headerName: 'Nome',
                                    flex: 1
                                },
                                {
                                    field: 'email',
                                    type: 'string',
                                    headerName: 'Email',
                                    flex: 1
                                },
                                {
                                    field: 'role',
                                    type: 'string',
                                    headerName: 'Cargo',
                                    flex: 1,
                                    valueGetter: (value) => {
                                        return ROLES.find(item => item.value === value)?.label
                                    }
                                },
                                {
                                    field: 'group',
                                    type: 'string',
                                    headerName: 'Grupo',
                                    flex: 1,
                                    valueGetter: (value: { name: string }) => {
                                        return value?.name ?? ''
                                    }
                                },
                                {
                                    field: 'active',
                                    type: 'boolean',
                                    headerName: 'Ativo',
                                    flex: .5
                                },
                                {
                                    field: 'actions',
                                    type: 'actions',
                                    getActions: (params: GridRowParams) => [
                                        <GridActionsCellItem icon={<VisibilityIcon />} onClick={() => {
                                            navigate(`/usuarios/${params.id}`)
                                        }} label="Ver Detalhes" />,
                                    ]
                                    
                                }
                            ]}
                            rows={users.data || []}
                        />
                    </Paper>
                </Grid>
            </Grid>
        </Layout>
    )
}