
import { css, } from "@emotion/react"
import styled from "@emotion/styled"
import { DataGrid, DataGridProps } from "@mui/x-data-grid"
import {ptBR} from '@mui/x-data-grid/locales/ptBR'

const StyledGrid = styled(DataGrid)`
    .MuiDataGrid-virtualScroller {
        min-height: 90px;
    }
`

export const Table = (props: DataGridProps) => {
    return (
        <StyledGrid
            localeText={{
                ...ptBR.components.MuiDataGrid.defaultProps.localeText,
                noRowsLabel: 'Não há registros'
            }}
            rowSelection={false}
            {...props}
        />
    )
}
