import { PropsWithChildren, useContext } from "react";
import { Navigate } from "react-router-dom";
import {AuthContext} from "../../contexts";

export const AuthRoute = ({ children }: PropsWithChildren): JSX.Element => {
    const { signed, isLoading } = useContext(AuthContext)

  if (!signed && !isLoading) {
    return <Navigate to="/" />
  }

  return children as unknown as JSX.Element;
};