import { useParams } from "react-router-dom"
import { Grid, Layout, Loader, LoaderContainer, Profile } from "../../../components"

export const UserPage = () => {
    const { id } = useParams()

    if (!id) {
        return (
            <LoaderContainer>
                <Loader />
            </LoaderContainer>
        )
    }

    return (
        <Layout>
            <Grid container justifyContent={'center'} padding={4}>
                <Grid item xs={10} sm={8} md={6} lg={4}>
                    <Profile userId={id} />
                </Grid>
            </Grid>
        </Layout>
    )
}