import React, { Fragment, useEffect, useState } from 'react';
import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import { useTheme } from '@mui/material';
import logoWhite from './logo-branca.png'
import {formatMoney} from '@bussola-viagens/utils'
// @ts-ignore
import FibraOneHeavy from './fibra-one-regular.otf'

Font.register({ family: 'Fibra One Heavy', format: "truetype", src: FibraOneHeavy });

interface OfferDocumentProps {
    buyer_name?: string
    flight_image_src?: string
    seller_name?: string
    checked_luggages?: string
    flight_extra_service?: string
    flight_cabin?: string
    final_price?: number
    payment_method?: string
    hotel_images_src?: string[]
    hotel_name?: string
    hotel_address?: string
    hotel_description?: string
    hotel_rules?: string
    hotel_price?: number
    flight_price?: number
    has_hotel?: boolean
    has_flight?: boolean
    id?: string
    preview?: boolean
    pix_qr_code?: string
}

const PRIMARY_COLOR = '#000096'

const styles = StyleSheet.create({
    page: {
        backgroundColor: 'white',
        color: PRIMARY_COLOR
        // fontFamily: 'FibraOneHeavy'
    },
    header: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        backgroundColor: PRIMARY_COLOR,
        color: 'white',
        textTransform: 'uppercase',
        padding: 15,
    },
    headerLogo: {
        height: 80,
    },
    headerTextContainer: {
        textAlign: 'center',
        justifyContent: 'space-between',
        alignItems: 'center'
    },
    headerText: {
    },
    sellerNameContainer: {
        textTransform: 'uppercase',
        padding: '5 20'
    },
    sellerNameText: {
        color: PRIMARY_COLOR,
    },
    sectionHeaderContainer: {
        backgroundColor: PRIMARY_COLOR,
        padding: '5 15',
        fontSize: 20,
    },
    sectionHeaderText: {
        color: 'white',
        textTransform: 'uppercase',
    },
    flightSectionContainer: {
        padding: 20,
        height: 250,
        flexGrow: 1,
        flexDirection: 'row'
    },
    hotelSectionContainer: {
        flexGrow: 1,
        flexDirection: 'column'
    },
    hotelImagesSectionContainer: {
        flexDirection: 'row',
        height: 150,
        justifyContent: 'space-evenly'
    }
});

export const OfferDocument = ({id, hotel_rules, preview,pix_qr_code, has_flight, final_price, has_hotel, hotel_name, hotel_price, flight_price, hotel_description, hotel_address, hotel_images_src, seller_name, payment_method, checked_luggages,buyer_name, flight_cabin, flight_extra_service, flight_image_src }: OfferDocumentProps) => {
    return (
        <Document>
            <Page size="A4" style={styles.page} wrap>
                {preview && (<View
                    style={{
                        alignItems: 'center',
                        display: 'flex',
                        height: '100%',
                        justifyContent: 'center',
                        left: 0,
                        position: 'absolute',
                        top: 0,
                        width: '100%',
                        zIndex: 4
                    }}
                >
                    <Text
                        style={{
                            color: 'rgba(0, 0, 0, 0.2)',
                            fontSize: 150,
                            fontWeight: 'bold',
                            textTransform: 'uppercase',
                            transform: 'rotate(-45deg)',
                        }}
                    >
                        PRÉVIA
                    </Text>
                </View>)}
                <View style={styles.header} fixed>
                    <View>
                        <Image src={logoWhite} style={styles.headerLogo}/>
                    </View>
                    <View style={styles.headerTextContainer}>
                        {id && <Text style={{...styles.headerText, fontSize: 6, color: 'white'}}>ID: {id}</Text>}
                        <Text style={{...styles.headerText, textDecoration: 'underline'}}>ORÇAMENTO DE VIAGEm</Text>
                        <Text style={styles.headerText}>{buyer_name || ' '}</Text>
                    </View>
                </View>
                <View style={styles.sellerNameContainer}>
                    <Text style={styles.sellerNameText}>Consultor: {seller_name}</Text>
                </View>
                {has_flight && (
                    <Fragment>
                        <View style={styles.sectionHeaderContainer}>
                            <Text style={styles.sectionHeaderText}>passagem aérea</Text>
                        </View>
                        <View style={styles.flightSectionContainer}>
                            <View style={{
                                flex: 2,
                                paddingRight: 50,
                                justifyContent: 'center'
                            }}>
                                {flight_image_src && (<Image src={flight_image_src} style={{ width: '100%' }} />)}
                            </View>
                            <View style={{
                                flex: 1,
                                justifyContent: 'center'
                            }}>
                                <Text style={{marginBottom: 10, textDecoration: 'underline'}}>INCLUSO:</Text>
                                <Text style={{ fontSize: 12 }}>• Taxa de embarque</Text>
                                {flight_cabin && (<Text style={{ fontSize: 12 }}>• Cabine {flight_cabin}</Text>)}
                                <Text style={{ fontSize: 12 }}>• 1 bagagem de mão de até 10kg</Text>
                                <Text style={{ fontSize: 12 }}>• {checked_luggages || '0'} bagagens despachadas de até 23kg</Text>
                                {flight_extra_service && (<Text style={{ fontSize: 12 }}>• {flight_extra_service.replace(/\n/g, "\n• ")}</Text>)}

                                <Text style={{textDecoration: 'underline', textTransform: 'uppercase', marginTop: 20, fontSize: 14}}>valor: {formatMoney(flight_price || 0)}</Text>
                            </View>
                        </View>
                    </Fragment>
                )}
                {has_hotel && (
                    <Fragment>
                        <View style={styles.sectionHeaderContainer}>
                            <Text style={styles.sectionHeaderText}>Hotel</Text>
                        </View>
                        <View style={styles.hotelSectionContainer}>
                            <View style={styles.hotelImagesSectionContainer}>
                                {
                                    hotel_images_src?.map(hi => {
                                        return (
                                            <Image src={hi} />
                                        )
                                    })
                                }
                            </View>
                            <View style={{padding: 20}}>
                                <Text style={{textDecoration: 'underline'}}>{hotel_name || ''}</Text>
                                <Text>{hotel_address || ' '}</Text>
                                {hotel_description && (<Text style={{ fontSize: 12, marginTop: 15 }}>• {hotel_description.replace(/\n/g, "\n• ") || ' '}</Text>)}

                                <Text style={{textDecoration: 'underline', textTransform: 'uppercase', marginTop: 20, fontSize: 14}}>valor: {formatMoney(hotel_price || 0)}</Text>
                            </View>
                        </View>
                    </Fragment>
                )}
                <View style={{
                    flexDirection: 'row'
                }}>
                    <View style={{
                        flexDirection: 'column',
                        flex: 2
                    }}>
                        <View style={{...styles.sectionHeaderContainer, backgroundColor: 'white'}}>
                            <Text style={{...styles.sectionHeaderText, color: PRIMARY_COLOR}}>Forma de Pagamento: {payment_method}</Text>
                        </View>
                        <View style={{...styles.sectionHeaderContainer, flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                            <Text style={styles.sectionHeaderText}>Valor Total: {formatMoney(final_price || 0)}</Text>
                        </View>
                    </View>
                </View>
                <View>
                    <View style={{backgroundColor: 'yellow', position: 'absolute', zIndex: 1, bottom: 0, right: 0, width: 150}}>
                        <Image src={pix_qr_code} />
                    </View> 
                </View>
            </Page>
            {hotel_rules && has_hotel&&  (
            <Page size="A4" style={styles.page}>
                <View style={styles.header} fixed>
                    <View>
                        <Image src={logoWhite} style={styles.headerLogo}/>
                    </View>
                    <View style={styles.headerTextContainer}>
                        {id && <Text style={{...styles.headerText, fontSize: 6, color: 'white'}}>ID: {id}</Text>}
                        <Text style={{...styles.headerText, textDecoration: 'underline'}}>ORÇAMENTO DE VIAGEm</Text>
                        <Text style={styles.headerText}>{buyer_name || ' '}</Text>
                    </View>
                </View>
                <View style={styles.sellerNameContainer}>
                    <Text style={styles.sellerNameText}>Consultor: {seller_name}</Text>
                </View>
                <View style={styles.sectionHeaderContainer}>
                    <Text style={styles.sectionHeaderText}>Regras do Hotel</Text>
                </View>
                <View style={{
                    padding: 10,
                    fontSize: 12
                }}>
                    <Text>
                        {hotel_rules}
                    </Text>
                </View>
            </Page>)}
        </Document>
    )
}


export const OfferPreview = (props: OfferDocumentProps) => {
    return ( 
        <PDFViewer style={{
            width: '100%',
            height: '100%'
        }}
        showToolbar={false}
        >
            <OfferDocument {...props} preview />
        </PDFViewer>
    )
}