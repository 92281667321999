import { createContext, PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";
import { useAPI } from "../hooks";
import { Loader } from "../components";
import { USER_ROLE, group, group_module, group_document, file } from "@bussola-viagens/database";
import { SignInSchema } from "@bussola-viagens/validations";

export interface User {
    id: string
    name: string
    role: USER_ROLE
    email: string
    group: group & { group_module?: group_module } & { group_document: Array<group_document & { file: file & { creator: { name: string, id: string } } }> }
}
interface AuthContextValues {
    signed: boolean,
    isLoading: boolean,
    signIn: (values: typeof SignInSchema.__outputType) => void
    signOut: (callback: () => void) => void
    me: User | null
}

export const AuthContext = createContext({} as AuthContextValues);

export const AuthContextProvider: React.FC<PropsWithChildren> = ({ children }) => {
    const [token, setToken] = useState('')

    const [me, refetchMe] = useAPI('/auth/me')
    
    const [login, loginAPI] = useAPI({
        url: '/auth/signin',
        method: 'POST'
    }, {
        manual: true
    })
    
    const signIn = useCallback(async (values: typeof SignInSchema.__outputType) => {
        const { data, status } = await loginAPI({
            data: values
        })

        if (status <= 299) {
            storeToken(data.token)
        }
    }, [loginAPI])

    const signOut = useCallback((callback: () => void) => {
        window?.localStorage?.removeItem('@bussola-viagens/token')

        setToken('')
        callback()
    }, [])

    const storeToken = (token: string) => {
        window?.localStorage?.setItem('@bussola-viagens/token', token)

        setToken(token)
    }

    useEffect(() => {
        const storedToken = window.localStorage.getItem('@bussola-viagens/token') || ''

        setToken(storedToken)
    }, [])

    useEffect(() => {
        if (token) {
            refetchMe()
        }
    }, [token])
    
    const isLoading = useMemo(() => {
        return login.loading || me.loading
    }, [login.loading, me.loading])

    if (isLoading) {
        return (
            <div style={{
                display: 'flex',
                flex: '1',
                height: '100vh',
                justifyContent: 'center',
                alignItems: 'center'
            }}>
                <Loader />
            </div>
        )
    }

 return (
   <AuthContext.Provider value={{ 
        signed: Boolean(me.data) && Boolean(token), 
        isLoading: isLoading,
        signIn,
        signOut,
        me: me.data
    }}>
     {children}
   </AuthContext.Provider>
 );
};