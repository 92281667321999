import * as React from 'react';
import { styled, Theme, CSSObject } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import LogoutIcon from '@mui/icons-material/Logout'
import PersonIcon from '@mui/icons-material/Person';
import { Tooltip, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import CalculateIcon from '@mui/icons-material/Calculate';
import { AuthContext } from '../../contexts';
import PeopleIcon from '@mui/icons-material/People';
import { USER_ROLE } from '@bussola-viagens/database';
import GroupsIcon from '@mui/icons-material/Groups';
import FolderIcon from '@mui/icons-material/Folder';
import SchoolIcon from '@mui/icons-material/School';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export function Layout({children}: {children: React.ReactNode}) {
  const [open, setOpen] = React.useState(false);
  const { me, signOut } = React.useContext(AuthContext)
  
  const navigate = useNavigate()
  const theme = useTheme()

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const onLogoutClick = React.useCallback(() => {
    signOut(() => {
      window.location.href = '/'
    })
  }, [signOut, navigate])
  
  const activeListItemStyles = React.useCallback((url: string) => {
    let basePathName = `/${window.location.pathname.split('/')[1]}`
    
    return url.includes(basePathName) ? {
      color: theme.palette.primary.main
    }: {}
  }, [theme])
  
  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap component="div" display="flex" flex={1}>
            Bem-vindo(a) à Bússola, {me?.name}!
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <div style={{
            display: 'flex',
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center'
          }}>
            <img alt="logo" src="/logo.png" height={50} />
          </div>
          {open && (<IconButton onClick={handleDrawerClose}>
            {!open ? <MenuIcon /> : <ChevronLeftIcon />}
          </IconButton>)}
        </DrawerHeader>
        <Divider />
        <List>
          <ListItem disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
                ...activeListItemStyles('/home')
              }}
              href={'/home'}
            >
            <Tooltip title='Home'>
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <CalculateIcon style={activeListItemStyles('/home')}/>
              </ListItemIcon>
              </Tooltip>
              <ListItemText primary='Home' sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
          {
            me?.group?.group_module?.is_offer_enabled ? (
              <ListItem disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    ...activeListItemStyles('/meus-orcamentos')
                  }}
                  href={'/meus-orcamentos'}
                >
                <Tooltip title='Orçamentos'>
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    <ReceiptLongIcon style={activeListItemStyles('/meus-orcamentos')}/>
                  </ListItemIcon>
                  </Tooltip>
                  <ListItemText primary='Orçamentos' sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            ) : null
          }
          {
            me?.group?.group_module?.is_documents_enabled ? (
              <ListItem disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    ...activeListItemStyles('/documentos')
                  }}
                  href={'/documentos'}
                >
                <Tooltip title='Documentos'>
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    <FolderIcon style={activeListItemStyles('/documentos')}/>
                  </ListItemIcon>
                  </Tooltip>
                  <ListItemText primary='Documentos' sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            ) : null
          }
          {
            me?.group?.group_module?.is_training_enabled ? (
              <ListItem disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    ...activeListItemStyles('/meus-treinamentos')
                  }}
                  href={'/meus-treinamentos'}
                >
                <Tooltip title='Treinamentos'>
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    <SchoolIcon style={activeListItemStyles('/meus-treinamentos')}/>
                  </ListItemIcon>
                  </Tooltip>
                  <ListItemText primary='Treinamentos' sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            ) : null
          }
          {
            me?.group?.group_module?.is_media_library_enabled ? (
              <ListItem disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    ...activeListItemStyles('/meus-bancos-de-midias')
                  }}
                  href={'/meus-bancos-de-midias'}
                >
                <Tooltip title='Bancos de Mídias'>
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    <PhotoLibraryIcon style={activeListItemStyles('/meus-bancos-de-midias')}/>
                  </ListItemIcon>
                  </Tooltip>
                  <ListItemText primary='Bancos de Mídias' sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            ) : null
          }
          {
            me?.group?.group_module?.is_promotion_enabled ? (
              <ListItem disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    ...activeListItemStyles('/minhas-promocoes')
                  }}
                  href={'/minhas-promocoes'}
                >
                <Tooltip title='Promoções'>
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    <MonetizationOnIcon style={activeListItemStyles('/minhas-promocoes')}/>
                  </ListItemIcon>
                  </Tooltip>
                  <ListItemText primary='Promoções' sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            ) : null
          }
          {
            me?.group?.group_module?.is_solicitation_enabled ? (
              <ListItem disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    ...activeListItemStyles('/meus-pedidos')
                  }}
                  href={'/meus-pedidos'}
                >
                <Tooltip title='Pedidos'>
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    <ShoppingBasketIcon style={activeListItemStyles('/meus-pedidos')}/>
                  </ListItemIcon>
                  </Tooltip>
                  <ListItemText primary='Pedidos' sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
            ) : null
          }
          <ListItem disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
                ...activeListItemStyles('/meu-perfil')
              }}
              href={'/meu-perfil'}
            >
            <Tooltip title='Meu Perfil'>
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <PersonIcon style={activeListItemStyles('/meu-perfil')}/>
              </ListItemIcon>
              </Tooltip>
              <ListItemText primary='Meu Perfil' sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
        </List>
        {
          me?.role === USER_ROLE.ADMIN ? (
            <>
              <Divider />
              <List
                style={{
                  display: 'flex',
                  flexDirection: 'column'
                }}
              >
              <ListItem disablePadding sx={{ display: 'block' }}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                      ...activeListItemStyles('/usuarios')
                    }}
                    href={'/usuarios'}
                  >
                  <Tooltip title='Usuários'>
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      <PeopleIcon style={activeListItemStyles('/usuarios')}/>
                    </ListItemIcon>
                    </Tooltip>
                    <ListItemText primary='Usuários' sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
              </ListItem>
              <ListItem disablePadding sx={{ display: 'block' }}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                      ...activeListItemStyles('/grupos')
                    }}
                    href={'/grupos'}
                  >
                  <Tooltip title='Grupos'>
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      <GroupsIcon style={activeListItemStyles('/grupos')}/>
                    </ListItemIcon>
                    </Tooltip>
                    <ListItemText primary='Grupos' sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
              </ListItem>
              <ListItem disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    ...activeListItemStyles('/orcamentos')
                  }}
                  href={'/orcamentos'}
                >
                <Tooltip title='Orçamentos'>
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    <ReceiptLongIcon style={activeListItemStyles('/orcamentos')}/>
                  </ListItemIcon>
                  </Tooltip>
                  <ListItemText primary='Orçamentos' sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
              <ListItem disablePadding sx={{ display: 'block' }}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                      ...activeListItemStyles('/treinamentos')
                    }}
                    href={'/treinamentos'}
                  >
                  <Tooltip title='Treinamentos'>
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      <SchoolIcon style={activeListItemStyles('/treinamentos')}/>
                    </ListItemIcon>
                    </Tooltip>
                    <ListItemText primary='Treinamentos' sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
              </ListItem>
              <ListItem disablePadding sx={{ display: 'block' }}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                      ...activeListItemStyles('/banco-de-midias')
                    }}
                    href={'/banco-de-midias'}
                  >
                  <Tooltip title='Banco de Mídias'>
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      <PhotoLibraryIcon style={activeListItemStyles('/banco-de-midias')}/>
                    </ListItemIcon>
                    </Tooltip>
                    <ListItemText primary='Banco de Mídias' sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
              </ListItem>
              <ListItem disablePadding sx={{ display: 'block' }}>
                  <ListItemButton
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? 'initial' : 'center',
                      px: 2.5,
                      ...activeListItemStyles('/promocoes')
                    }}
                    href={'/promocoes'}
                  >
                  <Tooltip title='Promoções'>
                    <ListItemIcon
                      sx={{
                        minWidth: 0,
                        mr: open ? 3 : 'auto',
                        justifyContent: 'center',
                      }}
                    >
                      <MonetizationOnIcon style={activeListItemStyles('/promocoes')}/>
                    </ListItemIcon>
                    </Tooltip>
                    <ListItemText primary='Promoções' sx={{ opacity: open ? 1 : 0 }} />
                  </ListItemButton>
              </ListItem>
              <ListItem disablePadding sx={{ display: 'block' }}>
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                    ...activeListItemStyles('/pedidos')
                  }}
                  href={'/pedidos'}
                >
                <Tooltip title='Pedidos'>
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : 'auto',
                      justifyContent: 'center',
                    }}
                  >
                    <ShoppingBasketIcon style={activeListItemStyles('/pedidos')}/>
                  </ListItemIcon>
                  </Tooltip>
                  <ListItemText primary='Pedidos' sx={{ opacity: open ? 1 : 0 }} />
                </ListItemButton>
              </ListItem>
              </List>
            </>
          ) : null
        }
        <Divider />
        <List
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column'
          }}
        >
          <ListItem disablePadding sx={{ display: 'block' }}>
            <ListItemButton
              sx={{
                minHeight: 48,
                justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
              onClick={onLogoutClick}
            >
            <Tooltip title='Logout'>
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
                <LogoutIcon />
              </ListItemIcon></Tooltip>
              <ListItemText primary='Logout' sx={{ opacity: open ? 1 : 0 }} />
            </ListItemButton>
          </ListItem>
          <div style={{
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'end',
            position: 'relative'
          }}>
            <img alt="logo" src="/logo-square.png" height={50} />
          </div>
        </List>
      </Drawer>
      <Box component="main" sx={{ 
        width: '100%',
        backgroundImage: `url(/map_bg.png)`, backgroundPosition: 'center', backgroundSize: 'cover', backgroundRepeat: 'no-repeat', 
        height: 'calc(100vh)',
        overflowY: 'auto'
        }}>
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  );
}
