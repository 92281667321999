import { Divider } from "@mui/material"
import ReactPDF, { pdf } from "@react-pdf/renderer"
import currency from "currency.js"
import { useFormik } from "formik"
import { MuiFileInput } from "mui-file-input"
import { enqueueSnackbar } from "notistack"
import { Fragment, useCallback, useContext, useEffect, useMemo, useState } from "react"
import { Button, CurrencyInput, FileUploader, Grid, Input, Layout, Paper, Switch, Typography, Loader, LoaderContainer } from "../../../components"
import { AuthContext } from "../../../contexts"
import { useAPI, useQuery, useToggle } from "../../../hooks"
import { OfferPreview, OfferDocument } from "./OfferPreview"
import {v4} from 'uuid'
import { Navigate } from "react-router-dom"
import { QrCodePix } from 'qrcode-pix';
import { CreateOfferSchema } from '@bussola-viagens/validations'

export const NewOfferPage = () => {
    const { me } = useContext(AuthContext)
    const query = useQuery()
    const [qrPix, setQrPix] = useState('')

    const [created, create] = useAPI({
        url: '/offer'
    }, { manual: true })

    const form = useFormik({
        initialValues: {
            buyer_name: query.get('buyer_name') || '',
            cabin: query.get('flight_cabin') || '',
            flight_photo: null,
            checked_luggages: query.get('checked_luggages') || '',
            flight_extra_service: query.get('flight_extra_service') || '',
            payment_method: query.get('payment_method') || '',
            hotel_photos: [],
            hotel_name: query.get('hotel_name') || '',
            hotel_address: query.get('hotel_address') || '',
            hotel_description: query.get('hotel_description') || '',
            hotel_rules: query.get('hotel_rules') || '',
            flight_price: query.get('flight_price') ? currency(query.get('flight_price') || 0).value : '',
            hotel_price: query.get('hotel_price') ? currency(query.get('hotel_price') || 0).value : '',
            has_flight: query.get('has_flight') ? query.get('has_flight') === 'true' : true,
            has_hotel: query.get('has_flight') ? query.get('has_flight') === 'true' : true,
        },
        validationSchema: CreateOfferSchema,
        onSubmit: async (values, helpers) => {
            const id = v4()
            
            const offer = await (await handleGenerateOfferDocument(id)).toBlob()
            const formData = new FormData();
            
            const data = {
                id,
                buyer_name: values.buyer_name,
                payment_method: values.payment_method,
                final_price: currency(Number(form.values.has_hotel ? (form.values.hotel_price || 0) : 0)).add(Number(form.values.has_flight ? (form.values.flight_price || 0) : 0)).value,
                has_flight: values.has_flight,
                has_hotel: values.has_hotel,
                flight_cabin: values.cabin,
                flight_checked_luggages: Number(values.checked_luggages || 0),
                flight_description: values.flight_extra_service,
                flight_price: values.flight_price,
                hotel_name: values.hotel_name,
                hotel_address: values.hotel_address,
                hotel_description: values.hotel_description,
                hotel_price: values.hotel_price,
                hotel_rules: values.hotel_rules
            }

            formData.append('data', JSON.stringify(data))
            
            formData.append('file', offer)
            
            const { status } = await create({
                method: 'POST',
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })

            if (status <= 299) {
                enqueueSnackbar('Orçamento criado com sucesso!', { variant: 'success' })
                window.open(URL.createObjectURL(offer), '_blank')
                helpers.resetForm()
            }
        }
    })

    const finalPrice = useMemo(() => {
        return currency(Number(form.values.has_hotel ? (form.values.hotel_price || 0) : 0)).add(Number(form.values.has_flight ? (form.values.flight_price || 0) : 0)).value
    }, [form.values.flight_price, form.values.has_flight, form.values.has_hotel, form.values.hotel_price])

    const offerDocumentProps = useMemo(() => {
        return {
            flight_image_src: form.values.flight_photo ? URL.createObjectURL(form.values.flight_photo) : undefined,
            hotel_images_src: form.values.hotel_photos.map(hi => URL.createObjectURL(hi)),
            buyer_name: form.values.buyer_name,
            checked_luggages: form.values.checked_luggages,
            seller_name: me?.name,
            flight_extra_service: form.values.flight_extra_service,
            flight_cabin: form.values.cabin,
            final_price: finalPrice,
            payment_method: form.values.payment_method,
            hotel_address: form.values.hotel_address,
            hotel_name: form.values.hotel_name,
            hotel_description: form.values.hotel_description,
            hotel_price: Number(form.values.hotel_price || 0),
            flight_price: Number(form.values.flight_price || 0),
            has_flight: form.values.has_flight,
            has_hotel: form.values.has_hotel,
            pix_qr_code: qrPix,
            hotel_rules: form.values.hotel_rules
        }
    }, [finalPrice, form.values.buyer_name, form.values.cabin, form.values.checked_luggages, form.values.flight_extra_service, form.values.flight_photo, form.values.flight_price, form.values.has_flight, form.values.has_hotel, form.values.hotel_address, form.values.hotel_description, form.values.hotel_name, form.values.hotel_photos, form.values.hotel_price, form.values.hotel_rules, form.values.payment_method, me?.name, qrPix])
    
    const generatePixCode = useCallback(async (id?: string) => {
        const qrCodePix = QrCodePix({
            version: '01',
            key: 'financeiro@bussolaviagens.com.br',
            name: 'R&E Turismo e Viagens LTDA',
            city: 'Anápolis',
            // transactionId: id,
            message: id,
            value: finalPrice
        })
        const qrCode = await qrCodePix.base64()
        
        return qrCode
    }, [finalPrice])

    const handleGenerateOfferDocument = useCallback(async (id: string) => {
        const qrCodePixWithId = await generatePixCode(id)
        
        return pdf(<OfferDocument {...offerDocumentProps} id={id} pix_qr_code={qrCodePixWithId} />)
    }, [generatePixCode, offerDocumentProps])

    useEffect(() => {
        generatePixCode().then(setQrPix)
    }, [])

    if (!me?.group.group_module?.is_offer_enabled) {
        return (
            <Navigate to="/home" />
        )
    }

    if (form.isSubmitting) {
        return (
            <LoaderContainer>
                <Loader />
            </LoaderContainer>
        )
    }
    
    return (
        <Layout>
            <Grid container p={1} spacing={1}>
                <Grid item xs={3}>
                    <Paper sx={{
                        p: 2
                    }}>
                        <Grid container>
                            <Grid item xs={4}/>
                            <Grid item xs={4}>
                                <Typography variant="h6" textAlign="center" mb={1}>
                                    Voo
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Switch 
                                    style={{
                                        justifyContent: 'end'
                                    }}
                                    checked={form.values.has_flight}
                                    onChange={() => {
                                        form.setFieldValue('has_flight', !form.values.has_flight, true)
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Input 
                                    name="cabin"
                                    label="Classe"
                                    value={form.values.cabin}
                                    onChange={form.handleChange}
                                    onBlur={form.handleBlur}
                                    error={!!form.touched.cabin && !!form.errors.cabin}
                                    helperText={form.touched.cabin && form.errors.cabin}
                                    disabled={!form.values.has_flight}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Input 
                                    name="checked_luggages"
                                    label="Bagagens Despachadas (23kg)"
                                    value={form.values.checked_luggages}
                                    onBlur={form.handleBlur}
                                    onChange={(event) => {
                                        form.setFieldValue('checked_luggages', event.target.value.replace(/\D/ig, ''))
                                    }}
                                    error={!!form.touched.checked_luggages && !!form.errors.checked_luggages}
                                    helperText={form.touched.checked_luggages && form.errors.checked_luggages}
                                    disabled={!form.values.has_flight}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Input 
                                    name="flight_extra_service"
                                    label="Observações"
                                    multiline
                                    maxRows={4}
                                    rows={4}
                                    value={form.values.flight_extra_service}
                                    onChange={form.handleChange}
                                    onBlur={form.handleBlur}
                                    error={!!form.touched.flight_extra_service && !!form.errors.flight_extra_service}
                                    helperText={form.touched.flight_extra_service && form.errors.flight_extra_service}
                                    disabled={!form.values.has_flight}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <FileUploader
                                    label="Imagem"
                                    onChange={(value) => {
                                        form.setFieldValue('flight_photo', value, true)
                                    }}
                                    value={form.values.flight_photo}
                                    inputProps={{
                                        accept: 'image/*'
                                    }}
                                    helperText={form.touched.flight_photo && form.errors.flight_photo}
                                    error={!!form.touched.flight_photo && !!form.errors.flight_photo}
                                    disabled={!form.values.has_flight}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CurrencyInput
                                    label="Valor (R$)"
                                    onChangeValue={(_, originalValue) => {
                                        form.setFieldValue('flight_price', originalValue, true)
                                    }}
                                    value={form.values.flight_price}
                                    disabled={!form.values.has_flight}
                                />
                            </Grid>
                        </Grid>
                        <Divider style={{
                            marginTop: 20,
                            marginBottom: 20
                        }}/>
                        <Grid container>
                            <Grid item xs={4}/>
                            <Grid item xs={4}>
                                <Typography variant="h6" textAlign="center" mb={1}>
                                    Hotel
                                </Typography>
                            </Grid>
                            <Grid item xs={4}>
                                <Switch 
                                    style={{
                                        justifyContent: 'end'
                                    }}
                                    checked={form.values.has_hotel}
                                    onChange={() => {
                                        form.setFieldValue('has_hotel', !form.values.has_hotel, true)
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={1}>
                                <Fragment>
                                    <Grid item xs={12}>
                                        <Input 
                                            name="hotel_name"
                                            label="Nome"
                                            value={form.values.hotel_name}
                                            onChange={form.handleChange}
                                            onBlur={form.handleBlur}
                                            error={!!form.touched.hotel_name && !!form.errors.hotel_name}
                                            helperText={form.touched.hotel_name && form.errors.hotel_name}
                                            disabled={!form.values.has_hotel}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Input 
                                            name="hotel_address"
                                            label="Endereço"
                                            value={form.values.hotel_address}
                                            onChange={form.handleChange}
                                            onBlur={form.handleBlur}
                                            error={!!form.touched.hotel_address && !!form.errors.hotel_address}
                                            helperText={form.touched.hotel_address && form.errors.hotel_address}
                                            disabled={!form.values.has_hotel}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Input 
                                            name="hotel_description"
                                            label="Observações"
                                            multiline
                                            maxRows={4}
                                            rows={4}
                                            value={form.values.hotel_description}
                                            onChange={form.handleChange}
                                            onBlur={form.handleBlur}
                                            error={!!form.touched.hotel_description && !!form.errors.hotel_description}
                                            disabled={!form.values.has_hotel}
                                            helperText={form.touched.hotel_description && form.errors.hotel_description}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Input 
                                            name="hotel_rules"
                                            label="Regras"
                                            multiline
                                            maxRows={4}
                                            rows={4}
                                            value={form.values.hotel_rules}
                                            onChange={form.handleChange}
                                            onBlur={form.handleBlur}
                                            error={!!form.touched.hotel_rules && !!form.errors.hotel_rules}
                                            disabled={!form.values.has_hotel}
                                            helperText={form.touched.hotel_rules && form.errors.hotel_rules}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <MuiFileInput
                                            label="Fotos"
                                            onChange={(value) => {
                                                form.setFieldValue('hotel_photos', value, true)
                                            }}
                                            multiple
                                            maxRows={4}
                                            value={form.values.hotel_photos}
                                            inputProps={{
                                                accept: 'image/*'
                                            }}
                                            fullWidth
                                            disabled={!form.values.has_hotel}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <CurrencyInput
                                            label="Valor (R$)"
                                            onChangeValue={(_, originalValue) => {
                                                form.setFieldValue('hotel_price', originalValue, true)
                                            }}
                                            value={form.values.hotel_price}
                                            disabled={!form.values.has_hotel}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Divider style={{
                                            marginTop: 20,
                                            marginBottom: 20
                                        }}/>
                                    </Grid>
                                </Fragment>
                            <Grid item xs={12}>
                                <Input 
                                    name="buyer_name"
                                    label="Cliente"
                                    value={form.values.buyer_name}
                                    onChange={form.handleChange}
                                    onBlur={form.handleBlur}
                                    error={!!form.touched.buyer_name && !!form.errors.buyer_name}
                                    helperText={form.touched.buyer_name && form.errors.buyer_name}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Input 
                                    name="payment_method"
                                    label="Forma de Pagamento"
                                    value={form.values.payment_method}
                                    onChange={form.handleChange}
                                    onBlur={form.handleBlur}
                                    error={!!form.touched.payment_method && !!form.errors.payment_method}
                                    helperText={form.touched.payment_method && form.errors.payment_method}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CurrencyInput
                                    label="Valor Total (R$)"
                                    onChangeValue={() => {}}
                                    value={finalPrice}
                                    disabled
                                />
                            </Grid>
                            <Grid item xs={12} textAlign="center"> 
                                <Button disabled={!form.values.has_flight && !form.values.has_hotel} onClick={() => form.handleSubmit()}>
                                    Gerar Orçamento
                                </Button>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
                <Grid item xs={9}>
                    <OfferPreview 
                        {...offerDocumentProps}
                    />
                </Grid>
            </Grid>
        </Layout>
    )
}